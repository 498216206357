<template>
  <router-link
    @touchstart.native="toggleClassHover"
    @touchend.native="toggleClassHover"
    :to="{ name: 'detail', params: { name: item.name }, query: { p: item.post_id } }"
    class="item"
  >
    <h4 class="item__name">
      <span class="item__jpname">{{ item.name }}</span>
      <span class="item__enname">{{ item.en_name }}</span>
    </h4>
    <span
      v-if="!uaEdge"
      :style="{ animationDelay: `${delay}ms`, backgroundImage: `url(${item.picture})` }"
      class="item__image">
    </span>
    <div v-else :style="{ animationDelay: `${delay}ms` }" class="item__image">
      <img :src="item.picture" :alt="item.name">
    </div>
    <div :style="{ animationDelay: `${delay}ms` }" class="item__cover"></div>
  </router-link>
</template>

<script>
export default {
  props: [
    'item',
    'linkto',
    'delay'
  ],
  methods: {
    toggleClassHover (event) {
      if(!event.currentTarget.classList.contains("is-hover")) {
        event.currentTarget.classList.add("is-hover")
      } else {
        event.currentTarget.classList.remove("is-hover")
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.item {
  font-size: 0;
  position: relative;
  overflow: hidden;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    transition: .3s ease;
  }
  @at-root {
    .item:hover::before,
    .item.is-hover::before {
      opacity: 0;
    }
  }
}

.item__name {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: .5rem;
  color: #fff;
  font-size: 1rem;
  text-align: center;
  transition: .3s ease;
  @at-root {
    .item:hover &,
    .item.is-hover & {
      opacity: 0;
      transform: scale(0);
    }
  }
}

.item__jpname {
  margin-bottom: .5rem;
  width: 100%;
  color: #fff;
  font-size: 1.125rem;
}

.item__enname {
  width: 100%;
  color: rgba(255, 255, 255, 0.9);
  font-size: 1rem
}

.item__image {
  display: block;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  transition: .3s ease;
  @at-root {
    .item:hover &,
    .item.is-hover & {
      transform: scale(1.1);
    }
  }
  >img {
    object-fit: cover;
    font-family: 'object-fit: cover;';
    width: 100%;
    height: 100%;
  }
}

.item__cover {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-color: #ddd;
  @media print {
    display: none;
  }
}
</style>