export default title => {
  document.title = title

  const children = document.head.children
  for (let i = 0; i < children.length; i++) {
    const element = children[i]
    if (element.getAttribute("property") == "og:url") {
      element.setAttribute("content", location.href)
    } else if(element.getAttribute("property") == "og:title") {
      element.setAttribute("content", title)
    }
  }
}