<template>
  <transition name="fade" mode="out-in" appear>
    <main class="c-main">
      <div class="notfound c-main__content">
        <p class="text">このURLは、移動または削除されたため、現在表示することができません。</p>
        <router-link to="/">[ホームに戻る]</router-link>
      </div>
    </main>
  </transition>
</template>

<script>
  export default {

  }
</script>

<style lang="scss" scoped>
  .notfound {
    width: 100%;
  }
  .text {
    margin-bottom: 1rem;
  }
</style>