<template>
  <transition name="fade" mode="out-in" appear>
    <main ref="home" class="home c-main">
      <side :subcatch="subcatch[activeSlide]"></side>

      <div ref="swiper" class="home__slider swiper-container c-main__content">
        <div class="swiper-wrapper">
          <section class="swiper-slide slide">
            <div class="slide__body">
              <figure
                class="slide__photo"
                data-wide
                data-swiper-parallax="30%"
                style="background-image: url(/dist/images/home/hero-bg.jpg)"
              ></figure>
              <div class="description">
                <div class="description__item">
                  <p class="description__title">茨城県伝統工芸品</p>
                  <p class="description__text">茨城県の風土と県民のくらしの中で育まれ受け継がれてきた伝統工芸品製造者等の製造意欲の高揚を図るとともに、工芸品に対する県民の認識を広めることを目的に、優れた伝統工芸品を知事が指定しています。</p>
                  <p class="description__caption">令和5年2月末現在41品目</p>
                </div>
                <div class="description__item">
                  <p class="description__title">茨城県伝統工芸士について</p>
                  <p class="description__text">茨城県指定伝統工芸品製造者等の社会的な声価を高めるとともに、従業意欲と技術の向上及び伝統工芸品の次代への継承に寄与することを目的に、製造技術・技能の研鑽を積まれ、高度の技術を保持する製造者等を知事が認定しています。</p>
                  <p class="description__caption">令和5年2月末現在81名</p>
                </div>
              </div>
            </div>
          </section>
          
          <section class="swiper-slide slide">
            <div class="slide__body">
              <figure class="slide__photo" data-wide data-swiper-parallax="30%" style="background-image: url(/dist/images/home/scene-special1.jpg)"></figure>
              <div class="special__card">
                <router-link to="/special/">
                  <h2 class="special__card-catch">奥久慈の自然が<br>最高の原料と<br>手漉きの技を支える</h2>
                  <p class="special__card-name">西ノ内和紙［常陸大宮市］<br>菊池 大輔さん</p>
                </router-link>
              </div>
            </div>
          </section>
          
          <section class="swiper-slide slide">
            <div class="slide__body">
              <h2 class="slide__catchcopy catchcopy">
                <span class="catchcopy__label">現代にフィットする伝統</span>
                <span class="catchcopy__category">［水府提燈］</span>
                <span class="catchcopy__scene">scene1:</span>
              </h2>
              <div class="slide__infobox infobox">
                <h3 class="infobox__itemname">三大提燈のひとつ「水府提燈」。<br>伝統に加え、灯の楽しさを提案。</h3>
                <div class="infobox__scrollbox js-ps">
                  <p class="infobox__caption">水戸の地で、江戸時代から生産される水府提燈。簡便に折り畳める提燈は、現代の暮らしや広告にマッチする新たな「光」として注目されています。光を灯す機能ではLED電球を採用するなど、安全で使いやすい提燈が生産され、癒しも感じられる灯りとして人気を集めています。</p>
                  <p class="infobox__enperson">Suifuchochin</p>
                  <p class="infobox__encaption">A Japanese paper lantern what is made in Suifu, Mito. It is very strong by holding the surface with a bamboo stick and strings from the inside. Suifu-chochin proposes new style of the light as adapted for life today, but still keeps the feature that folds away easily. The light with the lantern has peace of mind and it make you feel calm. For those reasons, it’s becoming new interior in daily life that takes advantages of Japanese paper and the structure of itself.</p>
                </div>
              </div>
              <figure class="slide__photo" data-swiper-parallax="30%" style="background-image: url(/dist/images/home/scene-bg1.jpg)"></figure>
            </div>
          </section>

          <section class="swiper-slide slide">
            <div class="slide__body">
              <h2 class="slide__catchcopy catchcopy">
                <span class="catchcopy__label">機能性という美学</span>
                <span class="catchcopy__category">［笠間焼］</span>
                <span class="catchcopy__scene">scene2:</span>
              </h2>
              <div class="slide__infobox infobox">
                <h3 class="infobox__itemname">高温直火対応の陶器製品の誕生。<br>笠間焼の魅力を広げる。</h3>
                <div class="infobox__scrollbox js-ps">
                  <p class="infobox__caption">笠間焼の耐火器。笠間焼協同組合が茨城県窯業指導所などとの素材研究に取り組み、高温直火には不適であった陶器の弱点を克服し高温直火に耐える陶器を完成させました。これによって鍋や炊飯器などの笠間焼がお使いいただけるようになりました。笠間焼の耐火器で炊いたご飯は、芯までふっくらと炊きあがり、また鍋料理などでも食卓の楽しみが増えたと喜ばれています。</p>
                  <p class="infobox__enperson">Kasamayaki</p>
                  <p class="infobox__encaption">Kasama pottery made in the city of Kasama has inherited the craft for over 200 years.  Now, lots of ceramic artists from around the world live in Kasama and produce their individual works.  A fireproof pottery has been produced lately, named ‘Kasamayaki.’  You can use this one pottery as a pot and a rice cooker etc. over an open fire.  The rice cooked in a Kasamayaki pot plumps up inside and tastes great.  Also, a fireproof Kasamayaki has been increasing pleasure on the table when being used as a casserole.</p>
                </div>
              </div>
              <figure class="slide__photo" data-swiper-parallax="30%" style="background-image: url(/dist/images/home/scene-bg2.jpg)"></figure>
            </div>
          </section>

          <section class="swiper-slide slide">
            <div class="slide__body">
              <h2 class="slide__catchcopy catchcopy" data-dark>
                <span class="catchcopy__label">粋をまとう暮らし</span>
                <span class="catchcopy__category">［本場結城紬］</span>
                <span class="catchcopy__scene">scene3:</span>
              </h2>
              <div class="slide__infobox infobox">
                <h3 class="infobox__itemname">織物の街「結城」の<br data-show="pc" class="c-show">新たな商品展開。<br>結城紬の伝統をもとに、<br data-show="pc" class="c-show">気楽に楽しめる織物の数々。</h3>
                <div class="infobox__scrollbox js-ps">
                  <p class="infobox__caption">しっかりと「自分らしさ」を表現したい。「本物志向」でありたい。暮らしでもファッションでも叶えていきたいことです。そのようなこだわりに、職人たちが丁寧にしあげる工芸品の品々は最適といえましょう。インターネットが発達し、グローバルな時代であるからこそ、かえって工芸品が生み出す「和」のテイスト、「トラディショナル」な魅力は、使い手の暮らしやファッションに豊かな幅を与えてくれ、ワンランク上の上質感を演出してくれるはずです。</p>
                  <p class="infobox__enperson">Yuki Tsumugi</p>
                  <p class="infobox__encaption">Yukitsumugi is a traditional silk textile made in Ibaraki Prefecture.  The hand-made texture is so beautiful.  It is designated as a UNESCO Intangible Cultural Heritage, and at the same time it features its modern senses.  Generally, tsumugi is made into kimono and obi, however, now we can see new products for interior such as fashion table runners and tapestries, as well as for fashion including shawls and scarves.  Yukitsumugi has drawn attention from abroad because of its tradition and feelings of novelty.</p>
                </div>
              </div>
              <figure class="slide__photo" data-swiper-parallax="30%" data-position-right style="background-image: url(/dist/images/home/scene-bg3.jpg)"></figure>
            </div>
          </section>
          <section class="swiper-slide slide">
            <div class="slide__body">
              <h2 class="slide__catchcopy catchcopy">
                <span class="catchcopy__label">引き立つ日常</span>
                <span class="catchcopy__category">［粟野春慶塗］</span>
                <span class="catchcopy__scene">scene4:</span>
              </h2>
              <div class="slide__infobox infobox">
                <h3 class="infobox__itemname">卓抜の技で丁寧に仕上げられた<br data-show="pc" class="c-show">粟野春慶塗。</h3>
                <div class="infobox__scrollbox js-ps">
                  <p class="infobox__caption">木の肌をそのままに残しながら、漆塗りで丁寧に仕上げられた粟野春慶塗の器や盆。厳選された木材を精緻な木工技術で仕上げ、茨城県産の漆を用い何度も塗り重ねて仕上げられています。特別な装飾もない素朴なモノでありながら、本当に使い手や、そこに置くものを、しっかりと支え、引き立ててくれます。</p>
                  <p class="infobox__enperson">Awano-Shunkeinuri</p>
                  <p class="infobox__encaption">This is one of the oldest lacquer crafts categorized as ‘shunkeinuri’ along with Hida-Shunkeinuri and Noshiro-Shunkeinuri, featuring its lacquer transparency applied to wooden materials with grain.  The lacquer ware looks a little bit simple, but once it is in your hand, you would find that it is really a refined artwork.  It might go well with a stylish interior decoration.  The gloss of the artwork would gradually change and turn deep color year by year.</p>
                </div>
              </div>
              <figure class="slide__photo" data-swiper-parallax="30%" style="background-image: url(/dist/images/home/scene-bg4.jpg)"></figure>
            </div>
          </section>

          <section class="swiper-slide slide">
            <div class="slide__body">
              <h2 class="slide__catchcopy catchcopy">
                <span class="catchcopy__label">想いを込めた贈りもの</span>
                <span class="catchcopy__category">［桂雛］</span>
                <span class="catchcopy__scene">scene5:</span>
              </h2>
              <div class="slide__infobox infobox">
                <h3 class="infobox__itemname">それぞれの人形ごとに異なる表情。<br>手仕事の美しさ。</h3>
                <div class="infobox__scrollbox js-ps">
                  <p class="infobox__caption">桂の雛人形は、丁寧な手仕事で作り上げられます。繊細な表情、仕草や美しい色彩感覚は、伝統とモダンを兼ね揃え海外の方にも高い評価を受けています。また結城紬など、茨城県の優れた伝統工芸を採用したり、お客様のご指定する着物などを利用した装束を作成することもできます。</p>
                  <p class="infobox__enperson">Katsurabina Doll</p>
                  <p class="infobox__encaption">These are hand-made dolls for the Girls’ Festival in March.  The costume and ornaments reflecting the Heian Period are so elegant and pretty.  Especially, the dolls made in the Katsura area, Ibaraki are very exquisite.  Their facial expressions, postures and use of color are regarded highly from overseas as well.  Katsurabina takes in Yukitsumugi silk textile which is also a traditional fine craft in Ibaraki.  You can select your dolls’ costume from the specified kimono patterns as you like.</p>
                </div>
              </div>
              <figure class="slide__photo" data-swiper-parallax="30%" style="background-image: url(/dist/images/home/scene-bg5.jpg)"></figure>
            </div>
          </section>

          <section class="swiper-slide slide">
            <div class="slide__body">
              <h2 class="slide__catchcopy catchcopy">
                <span class="catchcopy__label">暮らしのランドマーク</span>
                <span class="catchcopy__category">［真壁石燈籠］</span>
                <span class="catchcopy__scene">scene6:</span>
              </h2>
              <div class="slide__infobox infobox">
                <h3 class="infobox__itemname">いばらきの石を安らぎの形に。<br>匠の技と心。</h3>
                <div class="infobox__scrollbox js-ps">
                  <p class="infobox__caption">真壁町で古来から続く石燈籠。日本の御影石を代表する真壁産の石を用い、一筋に彫り続けてきた石工が丁寧に彫り上げます。伝統的な燈籠製作に加え、現代の邸宅やオフィスにもマッチしたり、小スペースでも設置できるようなものや、重ねる石を自在に組み合わせることができる遊び心のある新しいデザインの燈籠も製作されています。</p>
                  <p class="infobox__enperson">Makabe Stone Lantern</p>
                  <p class="infobox__encaption">A lantern made of stone which is produced in Makabe.  The stone lanterns have been used for Japanese gardens, shrines and temples since early times.  Makabe stone lanterns are made using granite yielded in Makabe.  Skilled stonemasons chisel the granite stone closely.  Also, various types of design for modern houses and offices have been provided lately.  Some of them can be put together freely.</p>
                </div>
              </div>
              <figure class="slide__photo" data-swiper-parallax="30%" style="background-image: url(/dist/images/home/scene-bg6.jpg)"></figure>
            </div>
          </section>
        </div>

        <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div>
      </div>
    </main>
  </transition>
</template>

<script>
  import side from "../components/side-home.vue"
  import initPs from "../mixin/init-ps"
  import offset from "../utility/offset"
  
  export default {
    components: { side },
    mixins: [ initPs ],
    data () {
      return {
        subcatch: [
          ["つくり手の想いが、", "郷土の暮らしに", "そっと寄り添う"],
          ["手しごとが育む", "地域の営み、", "くらしの風景。"],
          ["伝統にあぐらをかかず", "現代に生きる", "すべての方への愛情こそが", "モノつくりの原点"],
          ["素材研究や技術の革新", "より使いやすく", "機能性に富むモノたち"],
          ["工芸品が生み出す", "和のテイスト、", "トラディショナルな魅力が", "ワンランク上の上質感を演出"],
          ["工芸品の必然の美が", "大切なものを支え、", "暮らしの空間を", "静かに彩る"],
          ["精根を込め、", "丹精につくりあげた", "一つひとつの品は、", "一生のタカラモノ"],
          ["使ううちに、眺める度に、", "日常に忘れてはならない", "何かを静かに", "伝えてくれる"]
        ],
        activeSlide: 0
      }
    },

    methods: {
      initSwiper() {
        const swiper = new Swiper(this.$refs.swiper, {
          speed: 1000,
          parallax: true,
          spaceBetween: 150,
          autoHeight: window.matchMedia("(max-width:768px)").matches ? true : false,
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev"
          },
          simulateTouch: false,
          on: {
            // init: () => {
            //   this.$refs.swiper.style.height = this.$refs.home.clientHeight + "px"
            // },
            slideChange: () => {
              this.activeSlide = swiper.activeIndex
            }
          }
        })
      },
    },
    mounted() {
      window.addEventListener("load", () => this.initSwiper())
    },
    beforeRouteEnter(to, from, next) {
      next(vm=> vm.initSwiper())
    }
  }
</script>

<style lang="scss" scoped>
  @import "../scss/variable.scss";

  .home {
    display: flex;
    flex-direction: row-reverse;
    @media (max-width:768px) {
      position: relative;
      flex-direction: column;
    }
    &__slider {
      padding: 0;
      @media (max-width:768px) {
        width: 100%;
      }
    }
  }
  
  .swiper {
    &-container{
      height: 100%;
      @media (max-width:768px) {
        display: grid;
        grid-template: min-content auto / 50% 50%
      }
    }
    &-button-prev, &-button-next {
      transition: .3s ease;
      background-color: rgba(255, 255, 255, 0.5);
      width: 50px;
      height: 80px;
      @media (max-width:768px) {
        position: static;
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
        width: 44px;
        height: 30px;
        background-color: transparent;
        border-radius: 0;
      }
      &:hover {
        background-color: rgba(255, 255, 255, 0.8);
      }
    }
    &-button-prev {
      left: 0;
      background: url(/dist/images/common/arrow-left.svg) no-repeat center;
      border-radius: 0 4px 4px 0;
      @media (max-width:768px) {
        grid-area: 2 / 1;
        margin-left: 2rem;
      }
      &:hover {
        transform: translateX(-.25rem);
      }
    }
    &-button-next {
      right: 0;
      background: url(/dist/images/common/arrow-right.svg) no-repeat center;
      border-radius: 4px 0 0 4px;
      @media (max-width:768px) {
        grid-area: 2 / 2;
        margin-left: auto;
        margin-right: 2rem;
      }
      &:hover {
        transform: translateX(.25rem);
      }
    }
  }

  .slide {
    &:first-child .swiper-slide__body {
      @media (max-width:768px) {
        height: 20rem;
      }
    }
    &__body {
      position: relative;
      overflow: hidden;
      display: flex;
      height: 100%;
      @media (max-width:768px) {
        flex-direction: column;
      }
    }
    &__infobox {
      position: relative;
      z-index: 1;
      width: 30rem;
      padding: 8rem 3rem 0 6rem;
      @media (max-width:768px) {
        order: 3;
        padding: 0;
        width: 100%;
        height: 100%;
      }
    }
    &__photo {
      width: calc(100% - 30rem);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      @media (max-width:768px) {
        order: 1;
        width: 100%;
        height: 30rem;
      }
      &[data-wide] {
        width: 100%;
      }
      &[data-position-right] {
        @media (max-width:768px) {
          background-position: right;
        }
      }
    }
    &__catchcopy {
      position: absolute;
      right: 4rem;
      bottom: 3rem;
      z-index: 9;
      @media (max-width:768px) {
        position: static;
        order: 2;
        padding: 2rem 2rem 0;
        text-shadow: none !important;
        background: #fff;
        color: $black !important;
      }
    }
  }
  
  .special {
    &__card {
      position: absolute;
      left: 10rem;
      bottom: 5rem;
      z-index: 1;
      padding: 0 1rem 1rem;
      font-family: "Ryumin Medium KL", serif;
      background: #fff;
      transition: .3s;
      @media (max-width:768px) {
        position: static;
        order: 2;
        padding: 2rem;
      }
      &:hover {
        transform: translateY(-1px);
        box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
      }
      &::before {
        content: "";
        position: absolute;
        left: 0;
        bottom: 100%;
        width: 1rem;
        height: 1rem;
        border: .5rem solid transparent;
        border-bottom-color: #fff;
        border-right-color: #fff;
      }
      &::after {
        content: "";
        position: absolute;
        left: 1rem;
        bottom: 100%;
        width: calc(100% - 1rem);
        height: 1rem;
        background: #fff;
      }
    }
    &__card-catch {
      margin: 0 5rem 1rem 0;
    }
    &__card-name {
      margin-top: 1rem;
      text-align: right;
      font-feature-settings: "palt";
    }
  }

  .infobox {
    display: flex;
    flex-direction: column;
    background: url(/dist/images/common/stripe.png);
    background-size: 6px;
    @media (max-width:768px) {
      padding: 3rem 2rem 2rem;
      background: #fff;
    }
    &__scrollbox {
      position: relative;
      flex-grow: 1;
      @media (max-width:768px) {
        height: auto;
      }
      &:after {
        content: "";
        display: block;
        width: 100%;
        height: 2rem;
        @media (max-width:768px) {
          display: none;
        }
      }
    }
    &__itemname {
      margin-bottom: 3rem;
      font-size: 1.4rem;
      font-feature-settings: "palt";
      letter-spacing: .2em;
      line-height: 1.5;
      @media (max-width:768px) {
        margin-bottom: 1rem;
      }
    }
    &__caption {
      margin-bottom: 5rem;
      text-align: justify;
      line-height: 2;
      @media (max-width:768px) {
        margin-bottom: 2rem;
      }
    }
    &__enperson {
      margin-bottom: 1.5rem;
      font-weight: bold;
      font-size: 1.2rem;
      @media (max-width:768px) {
        margin-bottom: 1rem;
      }
    }
    &__encaption {
      text-align: justify;
      line-height: 2;
      text-align: left;
    }
  }

  .catchcopy {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    font-family: $weight-semibold;
    text-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    color: #fff;
    @media (max-width:768px) {
      align-items: flex-start;
    }
    &[data-dark] {
      color: $black;
    }
    &__label {
      font-size: 2.5vw;
      @media (max-width:768px) {
        font-size: 2rem;
      }
    }
    &__category {
      font-size: 2vw;
      font-family: $weight-semibold;
      font-feature-settings: "palt";
      @media (max-width:768px) {
        font-size: 1.5rem;
      }
    }
    &__scene {
      font-family: 'EB Garamond', serif;
      font-size: 5vw;
      line-height: 1;
      @media (max-width:768px) {
        font-size: 4.5rem;
      }
    }
  }

  .description {
    position: absolute;
    bottom: 1rem;
    left: 50%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    color: rgba(255, 255, 255, 0.8);
    font-weight: $weight-regular;
    width: 90%;
    max-width: 60rem;
    transform: translateX(-50%);
    @media (max-width:768px) {
      position: static;
      order: 2;
      grid-template-columns: 1fr;
      gap: 0;
      width: 100%;
      transform: none;
    }
    
    &__item {
      display: grid;
      grid-row: span 3;
      grid-template-rows: subgrid;
      gap: 0;
      padding: 1rem 2rem;
      background-color: rgba(0, 0, 0, .5);
      @media (max-width:768px) {
        padding: 2rem;
        background-color: rgba(0, 0, 0, .9);
      }
    }

    &__item:first-child {
      @media (max-width:768px) {
        border-bottom: 1px solid white;
      }
    }

    &__title {
      font-size: 1rem;
      margin-bottom: .5rem;
    }

    &__text {
      margin-bottom: .5rem;
      font-size: .875rem;
      line-height: 1.7;
    } 
    
    &__caption {
      font-size: .875rem;
      text-align: right;
    }
  }
</style>