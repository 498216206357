<template>    
  <aside class="side c-side">
    <transition-group name="slide-vertical" tag="h2" mode="out-in" @before-enter="beforeEnter" @enter-cancelled="afterEnter" @after-enter="afterEnter" appear class="side__subcatch subcatch">
      <span v-for="(lang, i) in subcatch" :key="lang" :data-index="i"> {{ lang }}</span>
    </transition-group>

    <figure class="side__logo logo">
      <img class="logo__mark" src="/dist/images/common/logo-product.svg" alt="茨城県伝統工芸品">
      <figucaption class="logo__caption">茨城県伝統工芸品</figucaption>
    </figure>
  </aside>
</template>

<script>
  export default {
    props: [
      "subcatch"
    ],

    methods: {
      beforeEnter: function (el) {
        el.style.transitionDelay = 200 * el.dataset.index + "ms"
      },
      afterEnter: function (el) {
        el.style.transitionDelay = "0ms"
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../scss/variable.scss";
  
  .side {
    display: flex;
    flex-direction: column;
    &__subcatch {
      margin-bottom: auto;
    }
    &__logo {
      margin: 3rem 2rem 0;
    }
  }

  .subcatch {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 20rem;
    padding-top: 4rem;
    background-color: #fff;
    font-size: 1.875rem;
    line-height: 1.7;
    letter-spacing: .2em;
    -ms-writing-mode: tb-rl;
    writing-mode: tb-rl;
    @media (max-width:768px) {
      position: static;
      margin: 3rem 0;
      padding-top: 0;
      width: 100%;
      font-size: 8vw;
    }
    @media (max-width:768px) and (orientation:landscape) {
      font-size: 2rem;
    }
  }

  .logo {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    @media (max-width:768px) {
      display: none;
      align-items: center;
    }
    &__mark {
      width: 4rem;
      margin-bottom: 2rem;
    }
    &__caption {
      font-size: 1.5rem;
      font-family: $weight-bold;
    }
  }

  @-moz-document url-prefix(){
    .subcatch {
      padding-left: 2rem;
      padding-right: 2rem;
      line-height: 1;
      >span {
        width: 2rem;
        margin: 0 .5rem;
        // &:first-child {
        //   align-self: end;
        // }
        // &:nth-child(2) {
        //   align-self: center;
        // }
        // &:last-child {
        //   align-self: start;
        // }
      }
    }
  }
</style>