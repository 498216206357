<template>
  <div>
    <my-header></my-header>
    <router-view class="c-main"></router-view>
    <my-footer></my-footer>
  </div>
</template>

<script>
import store from "../utility/store"
export default {
  created() {
    store.getData()
  }
}
</script>

<style lang="scss">
  @import "../scss/variable.scss";
  @import "../scss/transition.scss";

  * {
    box-sizing: border-box;
  }

  html {
    font-size: 100%;
  }
  body {
    display: block;
    min-width: 1280px;
    font-family: $weight-regular;
    font-size: 1rem;
    line-height: 1.4;
    @media (max-width:768px) {
      min-width: auto;
    }
  }
  img {
    max-width: 100%
  }
  a {
    color: inherit;
    text-decoration: none;
  }

  // Common Components
  .c-main {
    display: flex;
    height: calc(100vh - (8rem + 4rem));
    min-height: 45rem;
    @media (max-width:768px) {
      flex-direction: column-reverse;
      height: auto;
      min-height: auto;
    }
    &__content {
      overflow: hidden;
      position: relative;
      padding: 4rem 0 4rem 8rem;
      width: calc(100% - 18rem);
      height: 100%;
      background: url(/dist/images/common/stripe.png);
      background-size: 6px;
      @media (max-width:768px) {
        width: 100%;
        padding: 3rem 2rem 2rem;
      }
      @at-root {
        .c-main[data-one-col] & {
          width: 100%;
          padding: 0;
          @media (max-width:768px) {
            padding: 2rem;
            background: #fff;
          }
        }
      }
    }
    &__single {
      width: 65rem;
      height: 100%;
      margin: auto;
      @media (max-width:768px) {
        width: 100%;
      }
      &[data-single-min] {
        width: 40rem;
        @media (max-width:768px) {
          width: 100%;
        }
      }
      &:before {
        content: "";
        display: block;
        width: 100%;
        height: 4rem;
        @media (max-width:768px) {
          display: none;
        }
      }
      &:after {
        content: "";
        display: block;
        width: 100%;
        height: 4rem;
        @media (max-width:768px) {
          display: none;
        }
      }
      [data-scroll-start] {
        padding-top: 6rem;
        @media (max-width:768px) {
          padding-top: 0;
        }
      }
      [data-scroll-end] {
        padding-bottom: 4rem;
        @media (max-width:768px) {
          padding-bottom: 0;
        }
      }
    }
    &__side {
      position: relative;
      display: flex;
      flex-direction: column;
      min-width: 19rem;
      background-color: #fff;
      @media (max-width:768px) {
        justify-content: flex-start;
        width: 100%;
        min-width: auto;
        padding: 2rem 0;
      }
    }
    &__title {
      margin-bottom: 3rem;
      color: $black;
      font-size: 1.75rem;
      font-family: $weight-bold;
      @media (max-width:768px) {
        font-size: 1.5rem;
        text-align: center;
      }
    }
    &__caption {
      margin-bottom: 4rem;
      font-family: $weight-semibold;
      line-height: 1.7;
    }
  }
  
  .c-show {
    &[data-show="pc"] {
      @media (max-width:768px) {
        display: none;
      }
    }
    &[data-show="sp"] {
      @media (min-width:769px) {
        display: none;
      }
    }
  }

  .c-link {
    color: rgb(173, 173, 173);
    text-decoration: underline;
  }

  .c-btn {
    display: block;
    min-width: 9rem;
    padding: 0 1rem; 
    height: 2.75rem;
    background-color: $black;
    color: #fff;
    line-height: 2.75rem;
    transition: .3s ease;
    &[data-sub] {
      background-color: #747474;
    }
    &:disabled {
      opacity: .2;
      border: 1px solid $black;
      background-color: transparent;
      color: $black;
    }
    &:hover {
      transform: translate(1px, -1px);
    }
  }

  .ps__rail-x, .ps__rail-y {
    left: auto !important;
    right: 0 !important;
    display: block;
    opacity: 1;
  }
  .ps__thumb-y {
    background-color: rgb(218, 218, 218);
  }
</style>