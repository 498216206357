import Vue from "vue/dist/vue.esm.js"
import VueAnalytics from "vue-analytics"
import router from "./router"

Vue.use(VueAnalytics, {
  id: "UA-1948421-32",
  router,
  ignoreRoutes: ['detail'],
  autoTracking:{
    ageviewTemplate(route) {
      return{
        page: route.path,
        title: document.title,
        location: window.location.href
      }
    }
  }
})

import MyHeader from "./components/header.vue"
import MyFooter from "./components/footer.vue"
Vue.component("my-header", MyHeader)
Vue.component("my-footer", MyFooter)

const root = new Vue({
  el: "#main",
  router
})

const base = "/dist/css/"
var cssFileName = ["ress.min.css", "swiper.min.css", "perfect-scrollbar.min.css"];
for (let i = 0; i < cssFileName.length; i++) {
  const name = cssFileName[i];
  var link = document.createElement("link")
  link.setAttribute("rel", "stylesheet")
  link.setAttribute("href", base + name)
  document.body.insertAdjacentElement("beforeend", link) 
}