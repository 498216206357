<template>
  <header class="header">
    <router-link
      to="/"
      class="header__logo"
    >
      <span>いばらき手しごと帖</span>
      <span>KOGEI IBARAKI</span>
    </router-link>
    <transition name="fade">
      <ul v-show="isMenuShown" ref="menu" class="header__menu menu">
        <li><router-link to="/special/">特集</router-link></li>
        <li><router-link to="/craft/">茨城の伝統工芸品</router-link></li>
        <li><router-link to="/manufacturer/">製造者一覧</router-link></li>
      </ul>
    </transition>
    <div class="header__block">
      <router-link to="/craft/" class="header__search">
        <img src="/dist/images/common/search.svg" alt="Search">
      </router-link>
      <button @click="toggle" class="header__toggle">
        <img v-if="!isMenuShown" src="/dist/images/common/menu-open.svg" alt="Menu">
        <img v-if="isMenuShown" src="/dist/images/common/menu-close.svg" alt="MenuClose">
      </button>
    </div>
    <small class="header__copyright">Copylight(c) 2023 Ibaraki pref. All Rights Reserved.</small>
  </header>
</template>

<script>
  import closest from "../utility/closest"

  export default {
    data () {
      return {
        isMenuShown: false
      }
    },

    methods: {
      toggle() {
        this.isMenuShown = !this.isMenuShown
      }
    },

    mounted () {
      const largeMedia = window.matchMedia('(min-width: 768px)')
      largeMedia.addEventListener('change', (event) => {
        console.log(event.matches);
        if (event.matches) {
          this.isMenuShown = true
        } else {
          this.isMenuShown = false
        }
      })

      if (largeMedia.matches) {
        this.isMenuShown = true
      } else {
        document.addEventListener("click", ({ target }) => {
          if (target.closest(".header__toggle") || target.closest(".header__menu")) return
          this.isMenuShown = false
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .header {
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 8rem;
    padding: 1rem 2rem 0 5rem;
    @media (max-width:768px) {
      padding: 1rem 2rem;
      height: auto;
      border-bottom: 1px solid rgba(0,0,0,0.1);
    }
    &__logo {
      display: flex;
      align-items: center;
      align-self: center;
      gap: 2rem;
      font-family: "見出ゴMB1";
      font-size: 1.75rem;
      transition: .3s ease;
      @media (max-width:768px) {
        position: static;
        flex-direction: column;
        align-items: start;
        gap: .25rem;
        font-size: 1rem;
      }
      @media (max-width:320px) {
        font-size: .875rem;
      }
      &:hover {
        opacity: .5;
      }
      >span:first-child {
        letter-spacing: .2em;
        transform: scaleY(1.2);
        transform-origin: left;
        @media (max-width:768px) {
          transform-origin: center;
        }
      }
      >span:last-child {
        font-size: .75em;
        letter-spacing: .2em;
      }
    }
    &__block {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      @media (max-width:768px) {
        margin-right: -.75rem
      }
    }
    &__search {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 2.75rem;
      height: 2.75rem;
      transition: .3s ease;
      &:hover {
        opacity: .5;
      }
    }
    &__toggle {
      display: none;
      @media (max-width:768px) {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 2.75rem;
        height: 2.75rem;
        transition: .3s ease;
        &:hover {
          opacity: .5;
        }
      }
    }
    &__copyright {
      position: absolute;
      top: 1rem;
      right: 2.5rem;
      font-size: .75rem;
      letter-spacing: .1em;
      @media (max-width:768px) {
        display: none;
      }
    }
    &__menu {
      margin-left: auto;
      margin-right: 2rem;
      @media (max-width:768px) {
        position: absolute;
        right: 1.5rem;
        top: 4.5rem;
        width: 12rem;
        margin-right: 0;
      }
    }
  }

  .menu {
    display: flex;
    gap: 2rem;
    font-size: 1.25rem;
    list-style: none;
    @media (max-width:768px) {
      display: block;
      gap: 0;
      padding: .5rem 0;
      box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
      border: 1px solid rgb(220, 220, 220);
      border-radius: 4px;
      background-color: #fff;
      font-size: 1rem;
    }
    &:before, &:after {
      @media (max-width:768px) {
        content: "";
        position: absolute;
        bottom: 100%;
      }
    }
    &:before {
      right: .5rem;
      z-index: 1;
      border: transparent .5rem solid;
      border-bottom-color: #fff;
    }
    &:after {
      right: .45rem;
      z-index: 0;
      border: transparent .6rem solid;
      border-bottom-color: rgb(220, 220, 220);
    }
    a {
      display: block;
      transition: .3s ease;
      @media (max-width:768px) {
        padding: .5rem 1rem;
      }
      &:hover {
        text-decoration: underline;
        @media (max-width:768px) {
          background-color: #eee;
          text-decoration: none;
        }
      }
    }
  }
</style>