<template>    
  <aside class="side c-side">
    <ul class="side__menu menu">
      <li @click="spHandler" class="menu__parents">
        <span class="menu__parent">特集 | つくり手を訪ねて</span>
        <ul class="menu__child">
          <li>
            <router-link to="/special/">西ノ内紙<br>菊池 大輔さん</router-link>
          </li>
          <li>
            <router-link to="/special/">西ノ内紙<br>菊池 大輔さん</router-link>
          </li>
        </ul>
      </li>
    </ul>

    <ul class="side__menu menu" :class="{ 'is-craft': addClass }" ref="menu">
      <li v-for="parent in category.items" :key="parent.category" @click="catHandler($event, true)" class="menu__parents">
        <span class="menu__parent">{{ parent[0].category }} <span class="menu__countr">{{ parent.length }}</span></span>
        <ul class="menu__child">
          <li v-for="child in parent" :key="child.name">
            <router-link :to="{ name: 'detail', params: { name: child.name }, query: { p: child.post_id } }">{{ child.name }}</router-link>
          </li>
        </ul>
      </li>
    </ul>

    <figure class="side__logo logo">
      <img class="logo__mark" src="/dist/images/common/logo-product.svg" alt="茨城県伝統工芸品">
      <figucaption class="logo__caption">茨城県伝統工芸品</figucaption>
    </figure>
  </aside>
</template>

<script>
import closest from "../utility/closest"
import store from "../utility/store"

export default {
  props: [ "addClass" ],
  data: () => ({
    category: {
      items: store.state.categorise,
      actived: {
        element: undefined,
        defaultHeight: 0
      }
    },
    special: {
      actived: {
        element: undefined,
        defaultHeight: 0,
        state: false
      }
    }
  }),

  mounted() {
    window.addEventListener('load', () => {
      const parents = Array.from(document.getElementsByClassName('menu__parents'))
      parents.forEach(el => el.style.height = `${el.scrollHeight}px`)
    })
  },

  methods: {
    catHandler(event, emit) {
      if (this.category.actived.element === event.currentTarget) {
        this.catClose(emit)        
      } else {
        if (this.category.actived.element) this.catClose(emit)
        this.catOpen(event, emit)
      }
    },
    catOpen(event, emit) {
      const actived = this.category.actived
      actived.element = event.currentTarget
      actived.defaultHeight = actived.element.clientHeight
      actived.element.classList.add("is-active")
      actived.element.style.height = `${actived.element.scrollHeight}px`
      this.$refs.menu.classList.add("is-selected")
      if (emit) this.$emit("open", Array.from(actived.element.getElementsByTagName("li")))
    },
    catClose(emit) {
      const actived = this.category.actived
      actived.element.classList.remove("is-active")
      actived.element.style.height = `${actived.defaultHeight}px`
      actived.element = undefined
      actived.defaultHeight = 0
      this.$refs.menu.classList.remove("is-selected")
      if (emit) this.$emit("close")
    },
    spHandler(event) {
      this.special.actived.state ? this.spClose() : this.spOpen(event)
    },
    spOpen(event) {
      const actived = this.special.actived
      actived.element = event.currentTarget
      actived.defaultHeight =actived.element.clientHeight
      actived.state = true
      actived.element.classList.add("is-active")
      actived.element.style.height = `${actived.element.scrollHeight}px`
    },
    spClose(event) {
      const actived = this.special.actived
      actived.element.classList.remove("is-active")
      actived.element.style.height = `${actived.defaultHeight}px`
      actived.state = false
      actived.element = undefined
      actived.defaultHeight = 0
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../scss/variable.scss";

.side__menu {
  margin-bottom: auto;
  width: 100%;
  &:not(:last-of-type) {
    margin-bottom: 2rem;
  }
}

.side__logo {
  margin: 3rem 2rem 0;
}

.menu {
  list-style: none;
  cursor: pointer;
  // @media (max-width:768px) {
  //   padding: 2rem 0;
  // }
  &.is-craft.is-selected .menu__parents:not(.is-active) {
    @media (max-width:768px) {
      display: none;
    }
  }
}

.menu__parents {
  padding: .5rem 2rem;
  min-height: 2.75rem;
  line-height: 2.75rem;
  transition: .7s ease;
  &:hover {
    background-color: rgb(247, 247, 247);
  }
  &.is-active {
    margin: 1rem 0 2rem;
  }
  &:last-child.is-active {
    margin: 1rem 0 0;
  }
  &:first-child.is-active {
    margin: 0 0 2rem;
  }
  &:last-child {
    margin-bottom: 0;
  }
}

.menu__parent {
  display: block;
  line-height: 1.5;
  font-size: 1.25rem;
  transition: .7s ease;
  @media (max-width:768px) {
    font-size: 1rem;
  }
}

.menu__child {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  padding: 1rem 0 1rem 1rem;
  text-decoration: underline;
  list-style: disc;
  line-height: 1.4;
  @at-root {
    .menu__parents.is-active & {
      position: static;
      padding-bottom: 0;
      visibility: visible;
      opacity: 1;
      transition: .7s .5s ease;
    }
  }
}

.menu__child > li:not(:last-child) {
  margin-bottom: .25rem;
}

.menu__child > li > a {
  transition: .5s ease;
  @media (max-width:768px) {
    display: block;
    padding: .25rem 0;
  }
  &:hover, &.router-link-active {
    color: #b5b5b5;
  }
}

.menu__countr {
  color: #868686;
  font-size: 1rem;
}

.logo {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  @media (max-width:768px) {
    display: none;
    align-items: center;
  }
}

.logo__mark {
  width: 4rem;
  margin-bottom: 2rem;
}

.logo__caption {
  font-size: 1.5rem;
  font-family: $weight-bold;
}
</style>