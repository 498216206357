<template>
  <transition name="fade" mode="out-in" appear>
    <main class="c-main">
      <article class="c-main__content">
        <div ref="items" class="items c-main__single js-ps">
          <transition name="fade" mode="out-in" appear>
            <Loader v-if="!loaded" class="items__loader"></Loader>
            <transition-group
              v-else
              name="bes"
              tag="div"
              :class="{ 'is-loaded': loaded, 'is-highlight': item.highlights.length !== 0 }"
              class="items__grid">
              <router-link
                v-for="(item, i) in items"
                :key="item.name"
                :to="{ name: 'detail', params: { name: item.name }, query: { p: item.post_id } }"
                @touchstart.native="toggleClassHover"
                @touchend.native="toggleClassHover"
                :class="{ 'is-highlight': item.highlight }"
                class="items__item item">

                <h4 class="item__name">
                  <span class="item__jpname">{{ item.name }}</span>
                  <span class="item__enname">{{ item.en_name }}</span>
                </h4>

                <span
                  v-if="!uaEdge"
                  :style="{ animationDelay: `${70 * i}ms`, backgroundImage: `url(${item.picture})` }"
                  class="item__image"
                ></span>

                <div v-else :style="{ animationDelay: `${70 * i}ms` }" class="item__image">
                  <img :src="item.picture" :alt="item.name">
                </div>
                
                <div :style="{ animationDelay: `${70 * i}ms` }" class="item__cover"></div>
              </router-link>
            </transition-group>
          </transition>
        </div>
      </article>

      <SideList @open="setHighlight" @close="cancelHighlight" class="c-main__side js-ps"></SideList>
    </main>
  </transition>
</template>

<script>
import axios from "axios"
import initPs from "../mixin/init-ps"
import store from "../utility/store"
import SideList from "../components/side-list.vue"
import Loader from "../components/loader.vue"

export default {
  components: { SideList, Loader },
  mixins: [ initPs ],
  data: () => ({
    sharedState: store.state,
    item: {
      highlights: [],
      uaEdge: false
    }
  }),

  created() {
    const ua = window.navigator.userAgent.toLowerCase()
    this.uaEdge = ua.indexOf("edge") > -1 ? true: false
  },

  computed: {
    items() {
      let items = this.sharedState.items
      const highlights = this.item.highlights

      if (highlights.length === 0) {
        return items
      } else {
        items = items.map(item => {
          item.highlight = highlights.find(hl => item.name === hl.name) ? true : false
          return item
        })
        return items.sort((x, y) => {
          if (x.highlight === y.highlight) return 0
          return x.highlight ? -1 : 1
        })
      }
    },
    loaded() {
      return this.sharedState.items.length !== 0 ? true : false
    }
  },

  methods: {
    toggleClassHover(event) {
      if(!event.currentTarget.classList.contains("is-hover")) {
        event.currentTarget.classList.add("is-hover")
      } else {
        event.currentTarget.classList.remove("is-hover")
      }
    },
    setHighlight(items) {
      items.forEach(item => this.item.highlights.push({ name: item.textContent }))
    },
    cancelHighlight() {
      this.item.highlights = []
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../scss/variable.scss";

  .c-main {
    @media (max-width:768px) {
      flex-direction: column;
    }
  }

  .c-main__content {
    padding-top: 0;
    padding-bottom: 0;
  }

  .c-main__single {
    width: 100%;
    height: 100%;
  }

  .items {
    @media (max-width:768px) {
      min-height: 30rem;
    }
    &__loader {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      @media (max-width:768px) {
        top: 10%;
        transform: translate(-50%, 0);
      }
    }
    &__grid {
      position: relative;
      display: grid;
      grid-template-rows: repeat(auto-fill, 13rem);
      grid-template-columns: repeat(auto-fill, 13rem); 
      grid-gap: 2rem;
      width: 100%;
      @media (max-width:768px) {
        grid-template-rows: none;
        grid-template-columns: 100%;
        grid-gap: 1rem;
        width: 100%;
        padding: 3rem 0;
      }
    }
    &__item {
      width: 13rem;
      height: 13rem;
      @media (max-width:768px) {
        width: 100%;
        height: 8rem;
      }
      @at-root {
        .items__grid.is-loaded >& .item__image {
          animation: loaded-back-leave 1s ease-out;
        }
        .items__grid.is-loaded >& .item__cover {
          animation: loaded-front-leave 1s ease-out forwards;
        }
        .items__grid.is-highlight >&:not(.is-highlight) {
          pointer-events: none;
          opacity: .3;
        }
        .items__grid.is-highlight >&:not(.is-highlight):hover img {
          transform: none;
        }
      }
    }
  }

  .bes {
    &-move {
      transition: .7s ease;
    }
  }

  .item {
    font-size: 0;
    position: relative;
    overflow: hidden;
    &:before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.3);
      transition: .3s ease;
      @at-root {
        .item:hover::before,
        .item.is-hover::before {
          opacity: 0;
        }
      }
    }
    &__name {
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      padding: .5rem;
      color: #fff;
      font-size: 1rem;
      text-align: center;
      transition: .3s ease;
      @at-root {
        .item:hover &,
        .item.is-hover & {
          opacity: 0;
          transform: scale(0);
        }
      }
    }
    &__jpname {
      margin-bottom: .5rem;
      width: 100%;
      color: #fff;
      font-size: 1.125rem;
    }
    &__enname {
      width: 100%;
      color: rgba(255, 255, 255, 0.9);
      font-size: 1rem
    }
    &__image {
      display: block;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      transition: .3s ease;
      @at-root {
        .item:hover &,
        .item.is-hover & {
          transform: scale(1.1);
        }
      }
      >img {
        object-fit: cover;
        font-family: 'object-fit: cover;';
        width: 100%;
        height: 100%;
      }
    }
    &__cover {
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      background-color: #ddd;
      @media print {
        display: none;
      }
    }
  }

  @media all and (-ms-high-contrast:none){
    *::-ms-backdrop, .items__grid { 
      display: flex;
      flex-wrap: wrap;
      margin: -1rem 0 -1rem -1rem;
    }
    *::-ms-backdrop, .items__item {
      margin: 1rem;
      width: 13rem;
      height: 13rem;
    }
  }
</style>