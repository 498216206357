<template>
  <transition name="fade" mode="out-in" appear>
    <main class="c-main" data-one-col>
      <article class="c-main__content js-ps">
        <div class="c-main__single" data-single-min>
          <h2 class="c-main__title">個人情報保護方針</h2>
          <dl class="definition-list">
            <dt class="definition-list__head">個人情報の定義</dt>
            <dd class="definition-list__body">個人情報とは、個人に関する情報であり、氏名、電話番号、電子メールアドレス、特定の個人を識別し得る情報をいいます。</dd>
            <dt class="definition-list__head">個人情報の収集・利用</dt>
            <dd class="definition-list__body">
              本サイトは、以下の目的のためにその範囲内においてのみ、個人情報を収集・利用いたします。<br>
              本サイトによる個人情報の収集・利用は、利用者の自発的な提供によるものであり、利用者が個人情報を提供された場合は、本サイトが個人情報を利用することを利用者が許諾したものとします。<br>
              業務遂行上で必要となる本サイトからの問い合わせ、確認、およびサービス向上のための意見収集、各種のお問い合わせ対応に使用する場合があります。
            </dd>
            <dt class="definition-list__head">個人情報の第三者提供</dt>
            <dd class="definition-list__body">本サイトは、法令に基づく場合等の正当な理由によらない限り、事前に本人の同意を得ることなく、 個人情報を第三者に開示・提供することはありません。</dd>
            <dt class="definition-list__head">個人情報保護に関するお問い合わせ</dt>
            <dd class="definition-list__body">
              本サイトの個人情報の取り扱いに関する請求、お問い合わせは、下記の連絡先へお問い合わせくださいますようお願い申し上げます。
              <span class="definition-list__inner-content">茨城県商工労働観光部観光局観光物産課（平成30年4月からは、県産業政策課）<br>Tel.029-301-3622（平成30年4月からは029-301-3520）</span>
            </dd>
          </dl>
        </div>
      </article>
    </main>
  </transition>
</template>

<script>
  import initPs from "../mixin/init-ps"

  export default {
    mixins: [
      initPs
    ]
  }
</script>

<style lang="scss" scoped>
  @import "../scss/variable.scss";

  .definition-list {
    &__head {
      margin-bottom: 1rem;
      font-size: 1.25rem;
      font-family: $weight-semibold;
      &:before {
        content: "";
        display: inline-block;
        margin-right: .5rem;
        width: .5rem;
        height: 1rem;
        background-color: $black;
      }
    }
    &__body {
      color: $gray-light;
      text-align: justify;
      line-height: 1.7;
      margin-left: 1rem;
      @media (max-width:768px) {
        margin-left: 0;
      }
      &:not(:last-of-type) {
        margin-bottom: 2rem;
      }
    }
    &__inner-content {
      display: table;
      margin-top: 1rem;
      padding: 1.5rem 2rem;
      background-color: #fff;
      @media (max-width:768px) {
        margin-left: auto;
        margin-right: auto;
        background-color: rgb(245, 245, 245);
      }
    }
  }
</style>