import Vue from "vue/dist/vue.esm.js"
import VueRouter from "vue-router"
Vue.use(VueRouter)

import App from "./pages/app.vue"
import Home from "./pages/home.vue"
import Craft from "./pages/craft.vue"
import Detail from "./pages/detail.vue"
import Manufacturer from "./pages/manufacturer.vue"
import Privacy from "./pages/privacy.vue"
import Special from "./pages/special.vue"
import NotFound from "./pages/404.vue"

const router = new VueRouter({
  mode: 'history',
  base: "/",
  routes: [
    {
      path: "/", component: App,
      children: [
        {
          name: "home",
          path: "/",
          component: Home,
          meta: {
            title: "いばらき手しごと帖 The Living Crafts in Ibaraki",
          }
        }, {
          path: "/craft/",
          component: Craft,
          meta: {
            title: "茨城の伝統工芸品 - いばらき手しごと帖 The Living Crafts in Ibaraki"
          }
        }, {
          name: "detail",
          path: "/craft/:name/",
          component: Detail
        }, {
          path: "/manufacturer/",
          component: Manufacturer,
          meta: {
            title: "茨城県伝統工芸品製造者一覧 - いばらき手しごと帖 The Living Crafts in Ibaraki"
          }
        }, {
          path: "/privacy/",
          component: Privacy,
          meta: {
            title: "個人情報保護方針 - いばらき手しごと帖 The Living Crafts in Ibaraki"
          }
        }, {
          path: "/special/",
          component: Special,
          meta: {
            title: "特集 - いばらき手しごと帖 The Living Crafts in Ibaraki"
          }
        }, {
          path: '*',
          component: NotFound,
          meta: {
            title: "ページがありません - いばらき手しごと帖 The Living Crafts in Ibaraki"
          }
        }
      ]
    },
  ],
  
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else if(window.matchMedia("(max-width:768px)").matches || to.name !== "Detail") {
      return { x: 0, y: 0 } 
    }
  }
})

import ChangeHead from "./mixin/change-head"
router.beforeEach((to, from, next) => {
  if(to.meta.title) ChangeHead(to.meta.title)
  next()
})

export default router