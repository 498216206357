<template>
  <transition name="fade" mode="out-in" appear>
    <main key="detail">
      <article class="detail c-main__content">
        <transition :name="transitionName" mode="out-in">
          <div :key="count" class="detail__card card">
            <transition name="fade">
              <Loader v-if="!detail.loaded" key="loader" class="card__loader"></Loader>
            </transition>
            <transition name="fade">
              <div v-show="detail.loaded" key="inner" class="card__inner">
                <h3 class="card__name">{{ detail.no }} {{ detail.name }}</h3>

                <div ref="ps" class="card__scrollbox">
                  <p class="card__caption">{{ detail.caption }}</p>
                  <h4 class="card__city">［{{ detail.city }}］</h4>
                  <p class="card__enname">{{ detail.en_name }}</p>
                  <p class="card__encaption">{{ detail.en_caption }}</p>
                  <div class="card__manufacturer manufacturer">
                    <p class="manufacturer__title">製造者</p>
                    <div class="manufacturer__list">
                      <!-- TODO: 動的な表示を実装する -->
                      <div v-for="manufacturer in detail.manufacturers">
                        <p class="manufacturer__company" :data-show-icon="manufacturer.traditionalCraftsmanCertification">{{ manufacturer.name }}</p>
                        <div class="manufacturer__data">
                          <p class="manufacturer__address">{{ manufacturer.address }}</p>
                          <p class="manufacturer__tel"><span>TEL</span>{{ manufacturer.tel }}</p>
                          <p class="manufacturer__web"><span>WEB</span><a :href="manufacturer.web" target="_blank">{{ manufacturer.web }}</a></p>
                          <p class="manufacturer__feature"><span>特集</span><router-link to="/special/">つくりてを訪ねて</router-link></p>
                        </div>
                        <div class="manufacturer__en">
                          <p>Manufacturer :</p>
                          <p>Kasamayaki Cooperative Association</p>
                          <p>2481-5 Kasama, Kasama City</p>
                        </div>
                      </div>
                    </div>
                    <a class="manufacturer__map" href="https://www.google.com/maps/d/edit?mid=1tdnDS4BjVu2uQ3h1lKRL4l5euLy7A8k&usp=sharing" target="_blank">
                      <img src="/dist/images/detail/arrow.svg">製造者マップを見る
                    </a>
                  </div>
                </div>

                <span v-if="!uaEdge" class="card__image" :style="`background-image: url(${detail.picture})`">{{ detail.name }}</span>
                <figure v-else class="card__image"><img :src="detail.picture" :alt="detail.name"></figure>
              </div>
            </transition>
          </div>
        </transition>

        <router-link
          :to="{ name: 'detail', params: { name: detail.prev.name }, query: { p: detail.prev.post_id } }"
          @click.native="clickNavigation('slide-prev')"
          :class="{ 'is-desabled' : detail.prev.name == '' }"
          class="detail__prev">
        </router-link>
        <router-link
          :to="{ name: 'detail', params: { name: detail.next.name }, query: { p: detail.next.post_id } }"
          @click.native="clickNavigation('slide-next')"
          :class="{ 'is-desabled' : detail.next.name == '' }"
          class="detail__next">
        </router-link>
      </article>

      <SideList ref="menu" class="c-main__side js-ps"></SideList>
    </main>
  </transition>
</template>

<script>
import axios from "axios"
import initPs from "../mixin/init-ps"
import store from "../utility/store"
import closest from "../utility/closest"
import SideList from "../components/side-list.vue"
import Loader from "../components/loader.vue"

export default {
  components: { SideList, Loader },
  mixins: [ initPs ],

  data: () => ({
    sharedState: store.state,
    transitionName: "fade",
    count: 0,
    detail: {
      loaded: false,
      no: "",
      name: "",
      caption: "",
      city: "",
      picture: "",
      en_name: "",
      en_caption: "",
      prev: {
        name: "",
        post_id: ""
      },
      next: {
        name: "",
        post_id: ""
      },
      manufacturers: []
    },
    uaEdge: false
  }),

  created() {
    const ua = window.navigator.userAgent.toLowerCase()
    if(ua.indexOf("edge") > -1 || ua.indexOf("trident") > -1) {
      this.uaEdge = true
    } else {
      this.uaEdge = false
    }

    this.getDetail(this.$route.query.p)
  },

  mounted() {
    if(this.sharedState.categorise.length !== 0) {
      this.addActiveClass(this.$refs.menu.$el.querySelector(".router-link-active"))
    }
  },

  watch: {
    "indexListCategory" () {
      setTimeout(() => {
        this.addActiveClass(this.$refs.menu.$el.querySelector(".router-link-active"))
      }, 100)
    },
    "$route" (to, from) {
      setTimeout(() => {
        this.addActiveClass(this.$refs.menu.$el.querySelector(".router-link-active"))
      }, 100)
    }
  },

  beforeRouteUpdate (to, from, next) {
    this.detail.loaded = false
    this.getDetail(to.query.p)
    next()
  },

  methods: {
    getDetail (value) {
      axios.get(`http://demo.tokino.co.jp/wpkanri/?p=${value}`).then(response => {
        // const response = {
        //   data: {
        //     no: "01",
        //     name: "笠間焼",
        //     city: "笠間市",
        //     caption: "江戸時代後期から続く焼きものの産地。伝統を受け継ぎながらも、世界中から集まった多くの作家がそれぞれの個性を伸ばし、創造性豊かな作品を生み出している。",
        //     en_name: "Kasama-yaki",
        //     en_city: "Kasama",
        //     en_caption: "A style of Japanese pottery made in Kasama with a history of over 200 years. Unique pottery pieces are created by artists from around the world.",
        //     picture: "http:\/\/www.teshigoto-ibaraki.com\/wpkanri\/wp-content\/uploads\/2018\/03\/01-pottery-kasamayaki.jpg",
        //     prev: {
        //       name: "",
        //       post_id: ""
        //     },
        //     next: {
        //       name: "つくばね焼",
        //       post_id: 13
        //     }
        //   }
        // }
        const {
          no,
          name,
          caption,
          city,
          image,
          en_name,
          en_caption,
          picture,
          prev,
          next,
          manufacturers
        } = response.data

        console.log(manufacturers);

        this.detail = { 
          loaded: true,
          no,
          name,
          caption,
          city,
          picture,
          en_name,
          en_caption,
          prev: {
            name: prev.name !== "" ? prev.name : "",
            post_id: prev.post_id !== "" ? prev.post_id : ""
          },
          next: {
            name: next.name !== "" ? next.name : "",
            post_id: next.post_id !== "" ? next.post_id : ""
          },
          manufacturers: manufacturers.map(({ manufacturer }) => {
            return {
              name: manufacturer.post_title,
              address: manufacturer.acf.address,
              tel: manufacturer.acf.tel,
              traditionalCraftsmanCertification: manufacturer.acf.traditional_craftsman_certification,
              web: manufacturer.acf.web,
            }
          })
        }

        console.log(this.detail);

        document.title = `${name} - いばらき手しごと帖 KOGEI IBARAKI`
        this.$ga.page({
          path: this.$route.fullPath,
          title: document.title,
          location: window.location.href
        })
        const direction = this.uaEdge ? 1000 : 500
        setTimeout(()=> this.setScrollBox(this.$refs.ps), direction)
      })
    },
    setScrollBox (element) {
      if(window.matchMedia("(min-width:769px)").matches) {
        new PerfectScrollbar(element, {
          wheelSpeed: 10,
          minScrollbarLength: 20,
          suppressScrollX: true,
          wheelPropagation: true
        })
      }
    },
    clickNavigation (value) {
      this.transitionName = value == "slide-prev" ? "slide-prev" : "slide-next"
      this.count = value == "slide-prev" ? this.count - 1 : this.count + 1
    },
    addActiveClass (active) {
      const parentsAll = document.getElementsByClassName("menu__parents")
      for (let i = 0; i < parentsAll.length; i++) {
        const element = parentsAll[i];
        element.classList.remove("is-active")
        element.style.height =  "44px"
      }
      const parents = closest(active, ".menu__parents")
      parents.classList.add("is-active")
      parents.style.height = `${parents.scrollHeight}px`
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../scss/variable.scss";
  
  main {
    @media (max-width:768px) {
      flex-direction: column;
    }
  }
  
  .detail {
    width: 100%;
    height: 100%;
    @media (max-width:768px) {
      display: grid;
      grid-template-columns: 50% 50%;
      height: auto;
      padding: 0;
      background-color: #fff;
    }
    &__card {
      padding: 3rem 8rem 3rem 4rem;
      height: 100%;
      @media (max-width:768px) {
        grid-area: 1 / span 2;
        height: auto;
        min-height: 100vh;
        padding: 0 2rem;
      }
    }
    &__prev, &__next {
      position: absolute;
      top: 50%;
      width: 27px;
      height: 44px;
      margin-top: -22px;
      z-index: 10;
      background-size: 27px 44px;
      background-position: center;
      background-repeat: no-repeat;
      cursor: pointer;
      transition: .3s ease;
      @media (max-width:768px) {
        position: static;
        margin-top: 2rem;
        margin-bottom: 2rem;
      }
      &.is-desabled {
        opacity: .35;
        cursor: auto;
        pointer-events: none;
      }
    }
    &__prev {
      left: 2rem;
      background-image: url(/dist/images/common/arrow-left.svg);
      @media (max-width:768px) {
        grid-area: 2 / 1;
        margin-left: 2rem;
      }
      &:hover {
        transform: translateX(-.25rem);
      }
    }
    &__next {
      right: 2rem;
      background-image: url(/dist/images/common/arrow-right.svg);
      @media (max-width:768px) {
        grid-area: 2 / 2;
        margin-left: auto;
        margin-right: 2rem;
      }
      &:hover {
        transform: translateX(.25rem);
      }
    }
  }

  .card {
    position: relative;
    overflow: hidden;
    background-color: #fff;
    &__loader {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      @media (max-width:768px) {
        top: 10%;
        transform: translate(-50%, 0);
      }
    }
    &__inner {
      display: grid;
      grid-template: max-content 1fr / 55% 1fr;
      height: 100%;
      @media (max-width:768px) {
        grid-template: auto / auto;
        height: auto;
      }
    }
    &__name {
      grid-area: 1 / span 2;
      margin-bottom: 1rem;
      color: $black;
      font-size: 1.5rem;
      font-family: $weight-bold;
      @media (max-width:768px) {
        grid-area: 2 / 1;
      }
    }
    &__scrollbox {
      position: relative;
      grid-area: 2 / 1;
      margin-right: 3rem;
      @media (max-width:768px) {
        grid-area: 3 / 1;
        margin-right: 0;
        margin-bottom: 2rem;
      }
    }
    &__caption {
      margin-bottom: 1rem;
      color: $gray;
      font-family: $weight-semibold;
      line-height: 2;
      text-align: justify;
    }
    &__city {
      margin-bottom: 3rem;
      color: $black;
      font-family: $weight-semibold;
      font-size: 1.25rem;
      font-feature-settings: "palt";
    }
    &__enname {
      margin-bottom: 1rem;
      padding-top: 3rem;
      border-top: 1px solid rgba(0,0,0,.3);
      color: rgba(37,30,28,.85);
      font-size: 1.2rem;
      font-family: $weight-bold;
    }
    &__encaption {
      color: $gray;
      font-family: $weight-semibold;
      line-height: 2;
    }
    &__image {
      font-size: 0;
      grid-area: 2 / 2;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      @media (max-width:768px) {
        grid-area: 1 / 1;
        width: 100vw;
        height: 20rem;
        margin: 0 -2rem 3rem -2rem;
      }
      >img {
        object-fit: cover;
        font-family: 'object-fit: cover;';
        width: 100%;
        height: 100%;
      }
    }
    &__manufacturer {
      margin-top: 3rem;
      padding-top: 3rem;
      border-top: 1px solid rgba(0,0,0,.3);
      @media (max-width:768px) {
        margin-top: 3rem;
      }
    }
  }

  @media all and (-ms-high-contrast:none){
    *::-ms-backdrop, .card__inner { 
      display: flex;
      flex-wrap: wrap;
    }
    *::-ms-backdrop, .card__name {
      width: 100%;
    }
    *::-ms-backdrop, .card__scrollbox {
      width: calc(55% - 3rem);
    }
    *::-ms-backdrop, .card__image {
      width: 45%;
    }
  }

  .manufacturer {
    color: rgba(37, 30, 28, 0.75);
    &__title {
      margin-bottom: 2rem;
      color: rgba(37, 30, 28, 0.85);
      font-size: 1.2rem;
      font-family: "Ryumin Bold KL", serif;
    }
    &__list {
      margin-top: -1rem;
      margin-bottom: -1rem;
    }
    &__list > * {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
    &__list > * + * {
      border-top: 1px dashed #ccc;
    }
    &__company {
      display: flex;
      align-items: center;
      margin-bottom: .75rem;
      font-size: 1.125rem;
      text-align: right;
      font-feature-settings: "palt";
      @media (max-width:768px) {
        display: block;
        text-align: left;
      }
      &[data-show-icon]:after {
        content: "";
        margin-left: .5rem;
        width: 1em;
        height: 1.5em;
        background: url(/dist/images/common/logo-product-small.svg) no-repeat center/ contain;
        @media (max-width:768px) {
          display: inline-block;
          height: 1em;
        }
      }
    }
    &__data {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      gap: .25rem;
      margin-bottom: 1rem;
      @media (max-width:768px) {
        align-items: flex-start;
        font-size: 1rem;
      }
    }
    &__tel,
    &__web,
    &__feature {
      display: flex;
      align-items: center;
    }
    &__tel > span,
    &__web > span,
    &__feature > span {
      width: 3em;
      padding: .125rem;
      background-color: $gray;
      margin-right: .5rem;
      color: #fff;
      font-size: .75rem;
      text-align: center;
      line-height: 1;
      @media (max-width:768px) {
        margin-left: 0;
      }
    }
    &__feature > span {
      color: $black;
      background-color: #eee;
    }
    &__web > a,
    &__feature > a {
      text-decoration: underline;
    }
    &__en > p:first-child{
      font-size: .875rem;
    }
    &__map {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: .5rem;
      width: 100%;
      height: 4rem;
      margin-top: 3rem;
      border: 1px solid $gray-light;
      border-radius: 999px;
    }
  }
</style>