<template>
  <transition name="fade" mode="out-in" appear>
    <main class="c-main" data-one-col>
      <article class="c-main__content js-ps">
        <div class="c-main__single" data-single-min>
          <h2 class="c-main__title">茨城県伝統工芸品製造者一覧</h2>
          <div class="manufacturer">
            <p class="manufacturer__remarks c-main__caption">製造者本人又は従業員等が茨城県伝統工芸士の認定を受けています。</p>
            <div class="manufacturer__grid">
              <section class="manufacturer__category category">
                <h3 class="category__name">陶器</h3>
                <div class="item">
                  <p class="item__id">01</p>
                  <h4 class="item__name">【笠間焼】</h4>
                  <div class="item__box">
                    <p class="item__company" data-show-icon>笠間焼協同組合</p>
                    <div class="item__info">
                      <p class="item__address">笠間市笠間2481-5</p>
                      <p class="item__tel"><span>TEL</span>0296-73-0058</p>
                      <p class="item__web"><span>WEB</span><a href="https://teshigoto.co.jp" target="_blank">https://teshigoto.co.jp</a></p>
                    </div>
                  </div>
                </div>
                <div class="item">
                  <p class="item__id">02</p>
                  <h4 class="item__name">【つくばね焼】</h4>
                  <div class="item__box">
                    <p class="item__company">陶 梅田 ｜ 梅田 八主守</p>
                    <div class="item__info">
                      <p class="item__address">つくば市沼田1700-8</p>
                      <p class="item__tel"><span>TEL</span>029-866-2688</p>
                    </div>
                  </div>
                </div>
                <div class="item">
                  <p class="item__id">03</p>
                  <h4 class="item__name">【五浦天心焼】</h4>
                  <div class="item__box">
                    <p class="item__company">五浦天心焼事務局<br>（てんごころ内）</p>
                    <div class="item__info">
                      <p class="item__address">北茨城市平潟町235</p>
                      <p class="item__tel"><span>TEL</span>0293-46-0569</p>
                    </div>
                  </div>
                </div>
              </section>

              <section class="manufacturer__category category">
                <h3 class="category__name">木工品</h3>
                <div class="item">
                  <p class="item__id">04</p>
                  <h4 class="item__name">【粟野春慶塗】</h4>
                  <div class="item__box">
                    <p class="item__company" data-show-icon>稲川 義一</p>
                    <div class="item__info">
                      <p class="item__address">城里町大字粟458</p>
                      <p class="item__tel"><span>TEL</span>029-289-3346</p>
                    </div>
                  </div>
                </div>
                <div class="item">
                  <p class="item__id">06</p>
                  <h4 class="item__name">【結城地方の桐下駄】</h4>
                  <div class="item__box">
                    <p class="item__company" data-show-icon>猪ノ原木工所 ・ 桐の華工房<br>猪ノ原 昭廣</p>
                    <div class="item__info">
                      <p class="item__address">筑西市関本上345</p>
                      <p class="item__tel"><span>TEL</span>0296-37-6108</p>
                    </div>
                  </div>
                  <div class="item__box">
                    <p class="item__company">小久保商店 ｜ 小久保 定一</p>
                    <div class="item__info">
                      <p class="item__address">常総市本石下79</p>
                      <p class="item__tel"><span>TEL</span>0297-42-2215</p>
                    </div>
                  </div>
                </div>
                <div class="item">
                  <p class="item__id">07</p>
                  <h4 class="item__name">【結城桐箪笥】</h4>
                  <div class="item__box">
                    <p class="item__company" data-show-icon>堀江桐タンス店 ｜ 堀江 準一</p>
                    <div class="item__info">
                      <p class="item__address">結城市結城1645</p>
                      <p class="item__tel"><span>TEL</span>0296-33-2063</p>
                    </div>
                  </div>
                  <div class="item__box">
                    <p class="item__company" data-show-icon>有限会社桐タンスのヤマキヤ<br>秋山 利夫</p>
                    <div class="item__info">
                      <p class="item__address">結城市結城811-5</p>
                      <p class="item__tel"><span>TEL</span>0296-33-3353</p>
                    </div>
                  </div>
                  <div class="item__box">
                    <p class="item__company" data-show-icon>大塚桐たんす店 ｜ 大塚 正美</p>
                    <div class="item__info">
                      <p class="item__address">結城市結城13557</p>
                      <p class="item__tel"><span>TEL</span>0296-33-2839</p>
                    </div>
                  </div>
                </div>
                <div class="item">
                  <p class="item__id">08</p>
                  <h4 class="item__name">【水戸やなかの桶】</h4>
                  <div class="item__box">
                    <p class="item__company">友部桶製造店 ｜ 友部 次男</p>
                    <div class="item__info">
                      <p class="item__address">水戸市末広町3-5-14</p>
                      <p class="item__tel"><span>TEL</span>029-231-3527</p>
                    </div>
                  </div>
                </div>
                <!-- <div class="item">
                  <p class="item__id">09</p>
                  <h4 class="item__name">【とよさとの桶・樽】</h4>
                  <div class="item__box">
                    <p class="item__company">本橋桶店 ｜ 本橋 武夫</p>
                    <div class="item__info">
                      <p class="item__address">つくば市上郷6829-1</p>
                      <p class="item__tel"><span>TEL</span>029-847-3507</p>
                    </div>
                  </div>
                </div> -->
                <div class="item">
                  <p class="item__id">09</p>
                  <h4 class="item__name">【涸沼竿】</h4>
                  <div class="item__box">
                    <p class="item__company">東明工房 ｜ 川上 東明</p>
                    <div class="item__info">
                      <p class="item__address">水戸市水府町1570-28</p>
                      <p class="item__tel"><span>TEL</span>029-225-6835</p>
                    </div>
                  </div>
                </div>
                <div class="item">
                  <p class="item__id">10</p>
                  <h4 class="item__name">【石岡府中杉細工】</h4>
                  <div class="item__box">
                    <p class="item__company" data-show-icon>工芸物産 ｜ 福田 弘</p>
                    <div class="item__info">
                      <p class="item__address">石岡市高浜825</p>
                      <p class="item__tel"><span>TEL</span>0299-26-3221</p>
                    </div>
                  </div>
                </div>
                <div class="item">
                  <p class="item__id">11</p>
                  <h4 class="item__name">【石岡の桐箱】</h4>
                  <div class="item__box">
                    <p class="item__company">高安桐工芸 ｜ 高安 尚訓</p>
                    <div class="item__info">
                      <p class="item__address">石岡市村上286-1</p>
                      <p class="item__tel"><span>TEL</span>0299-23-2601</p>
                    </div>
                  </div>
                </div>
              </section>

              <section class="manufacturer__category category">
                <h3 class="category__name">籐工芸</h3>
                <div class="item">
                  <p class="item__id">12</p>
                  <h4 class="item__name">【茨城籐工芸】</h4>
                  <div class="item__box">
                    <p class="item__company">オキナヤ 籐工芸 ｜ 山田 和幸</p>
                    <div class="item__info">
                      <p class="item__address">下妻市小野子町2-61</p>
                      <p class="item__tel"><span>TEL</span>0296-44-3986</p>
                    </div>
                  </div>
                  <div class="item__box">
                    <p class="item__company">ラタンファニチャー堀江 ｜ 堀江 正則</p>
                    <div class="item__info">
                      <p class="item__address">神栖市波崎6538-8</p>
                      <p class="item__tel"><span>TEL</span>0479-44-4848</p>
                    </div>
                  </div>
                </div>
              </section>

              <section class="manufacturer__category category">
                <h3 class="category__name">竹工品</h3>
                <div class="item">
                  <p class="item__id">13</p>
                  <h4 class="item__name">【竹矢】</h4>
                  <div class="item__box">
                    <p class="item__company">義政（有限会社弓屋） ｜ 小池 和義</p>
                    <div class="item__info">
                      <p class="item__address">石岡市小幡830-3</p>
                      <p class="item__tel"><span>TEL</span>0299-42-3376</p>
                    </div>
                  </div>
                  <div class="item__box">
                    <p class="item__company">義行 ｜ 助川 弘喜</p>
                    <div class="item__info">
                      <p class="item__address">石岡市小幡849</p>
                      <p class="item__tel"><span>TEL</span>0299-42-3659</p>
                    </div>
                  </div>
                </div>
                <div class="item">
                  <p class="item__id">14</p>
                  <h4 class="item__name">【雪村うちわ】</h4>
                  <div class="item__box">
                    <p class="item__company">枡儀団扇店 ｜ 圷 總子</p>
                    <div class="item__info">
                      <p class="item__address">常陸太田市塙町2274</p>
                      <p class="item__tel"><span>TEL</span>0294-72-7159</p>
                    </div>
                  </div>
                </div>
                <div class="item">
                  <p class="item__id">15</p>
                  <h4 class="item__name">【古河竹工画】</h4>
                  <div class="item__box">
                    <p class="item__company" data-show-icon>梅田 文雄</p>
                    <div class="item__info">
                      <p class="item__address">古河市東2-23-14</p>
                      <p class="item__tel"><span>TEL</span>0280-31-1509</p>
                    </div>
                  </div>
                  <div class="item__box">
                    <p class="item__company">小林 富雄</p>
                    <div class="item__info">
                      <p class="item__address">古河市上大野906-1</p>
                      <p class="item__tel"><span>TEL</span>0280-98-0393</p>
                    </div>
                  </div>
                </div>
              </section>

              <section class="manufacturer__category category">
                <h3 class="category__name">和紙・文具</h3>
                <div class="item">
                  <p class="item__id">16</p>
                  <h4 class="item__name">【西ノ内和紙】</h4>
                  <div class="item__box">
                    <p class="item__company">五介和紙 ｜ 菊池 浩</p>
                    <div class="item__info">
                      <p class="item__address">常陸大宮市山方1323</p>
                      <p class="item__tel"><span>TEL</span>0295-57-6647</p>
                    </div>
                  </div>
                  <div class="item__box">
                    <p class="item__company">紙のさと ｜ 菊池 正気・大輔</p>
                    <div class="item__info">
                      <p class="item__address">常陸大宮市舟生90</p>
                      <p class="item__tel"><span>TEL</span>0295-57-2252</p>
                    </div>
                  </div>
                </div>
                <div class="item">
                  <p class="item__id">17</p>
                  <h4 class="item__name">【かな料紙】</h4>
                  <div class="item__box">
                    <p class="item__company">小室 久</p>
                    <div class="item__info">
                      <p class="item__address">常陸太田市大菅町211-2</p>
                      <p class="item__tel"><span>TEL</span>0294-82-2451</p>
                    </div>
                  </div>
                </div>
                <div class="item">
                  <p class="item__id">18</p>
                  <h4 class="item__name">【国寿石大子硯】</h4>
                  <div class="item__box">
                    <p class="item__company">大子硯工房「岱山」 ｜ 佐藤 弘</p>
                    <div class="item__info">
                      <p class="item__address">大子町大字袋田753-1</p>
                      <p class="item__tel"><span>TEL</span>0295-72-1361</p>
                    </div>
                  </div>
                </div>
              </section>

              <section class="manufacturer__category category">
                <h3 class="category__name">織物・染物</h3>
                <div class="item">
                  <p class="item__id">19</p>
                  <h4 class="item__name">【本場結城紬】</h4>
                  <div class="item__box">
                    <p class="item__company">本場結城紬卸商協同組合</p>
                    <div class="item__info">
                      <p class="item__address">結城市結城1360-1</p>
                      <p class="item__tel"><span>TEL</span>0296-33-2333</p>
                    </div>
                  </div>
                </div>
                <div class="item">
                  <p class="item__id">20</p>
                  <h4 class="item__name">【いしげ結城紬】</h4>
                  <div class="item__box">
                    <p class="item__company">茨城県結城郡織物協同組合</p>
                    <div class="item__info">
                      <p class="item__address">常総市新石下3666</p>
                      <p class="item__tel"><span>TEL</span>0297-42-2201</p>
                    </div>
                  </div>
                </div>
                <div class="item">
                  <p class="item__id">21</p>
                  <h4 class="item__name">【水海道染色村きぬの染】</h4>
                  <div class="item__box">
                    <p class="item__company">石山 修</p>
                    <div class="item__info">
                      <p class="item__address">常総市坂手町5538-30</p>
                      <p class="item__tel"><span>TEL</span>0297-27-3025</p>
                    </div>
                  </div>
                  <div class="item__box">
                    <p class="item__company">佐古染色工芸館 ｜ 佐古 章</p>
                    <div class="item__info">
                      <p class="item__address">常総市坂手町5538-28</p>
                      <p class="item__tel"><span>TEL</span>0297-27-2053</p>
                    </div>
                  </div>
                  <div class="item__box">
                    <p class="item__company">小林 義一</p>
                    <div class="item__info">
                      <p class="item__address">常総市坂手町5538-98</p>
                      <p class="item__tel"><span>TEL</span>0297-27-0164</p>
                    </div>
                  </div>
                  <div class="item__box">
                    <p class="item__company">宮嶋 康哲</p>
                    <div class="item__info">
                      <p class="item__address">常総市坂手町5538-6</p>
                      <p class="item__tel"><span>TEL</span>0297-27-2057</p>
                    </div>
                  </div>
                  <div class="item__box">
                    <p class="item__company">奥谷 好文</p>
                    <div class="item__info">
                      <p class="item__address">常総市坂手町5538-88</p>
                      <p class="item__tel"><span>TEL</span>0297-27-3611</p>
                    </div>
                  </div>
                </div>
              </section>

              <section class="manufacturer__category category">
                <h3 class="category__name">祭礼・用具など</h3>
                <div class="item">
                  <p class="item__id">22</p>
                  <h4 class="item__name">【常陸獅子】</h4>
                  <div class="item__box">
                    <p class="item__company" data-show-icon>櫻井 光保</p>
                    <div class="item__info">
                      <p class="item__address">石岡市石岡2747-5</p>
                      <p class="item__tel"><span>TEL</span>090-3227-7531</p>
                    </div>
                  </div>
                  <div class="item__box">
                    <p class="item__company">宮本  力</p>
                    <div class="item__info">
                      <p class="item__address">かすみがうら市上稲吉1862-5</p>
                      <p class="item__tel"><span>TEL</span>090-2627-9216</p>
                    </div>
                  </div>
                  <div class="item__box">
                    <p class="item__company">来栖 保</p>
                    <div class="item__info">
                      <p class="item__address">石岡市若宮1-2-25</p>
                      <p class="item__tel"><span>TEL</span>0299-23-0895</p>
                    </div>
                  </div>
                </div>
                <div class="item">
                  <p class="item__id">23</p>
                  <h4 class="item__name">【水府提灯】</h4>
                  <div class="item__box">
                    <p class="item__company">株式会社青野商店</p>
                    <div class="item__info">
                      <p class="item__address">水戸市新荘1-5-50</p>
                      <p class="item__tel"><span>TEL</span>029-221-2491</p>
                    </div>
                  </div>
                  <div class="item__box">
                    <p class="item__company">株式会社蔭山利兵衛商店</p>
                    <div class="item__info">
                      <p class="item__address">水戸市本町3-3-7</p>
                      <p class="item__tel"><span>TEL</span>029-221-3666</p>
                    </div>
                  </div>
                  <div class="item__box">
                    <p class="item__company">株式会社鈴木茂兵衛商店</p>
                    <div class="item__info">
                      <p class="item__address">水戸市袴塚1-7-5</p>
                      <p class="item__tel"><span>TEL</span>029-221-3966</p>
                    </div>
                  </div>
                </div>
                <div class="item">
                  <p class="item__id">24</p>
                  <h4 class="item__name">【桂雛】</h4>
                  <div class="item__box">
                    <p class="item__company">有限会社桂雛 ｜ 小佐畑 孝雄</p>
                    <div class="item__info">
                      <p class="item__address">城里町阿波山1186</p>
                      <p class="item__tel"><span>TEL</span>029-289-3246</p>
                    </div>
                  </div>
                </div>
                <div class="item">
                  <p class="item__id">25</p>
                  <h4 class="item__name">【万祝・大漁旗】</h4>
                  <div class="item__box">
                    <p class="item__company">中山染工場 ｜ 中山 精一</p>
                    <div class="item__info">
                      <p class="item__address">大洗町磯浜町459</p>
                      <p class="item__tel"><span>TEL</span>029-267-3256</p>
                    </div>
                  </div>
                  <div class="item__box">
                    <p class="item__company">大森染工場 ｜ 大森 幸二</p>
                    <div class="item__info">
                      <p class="item__address">ひたちなか市東本町19-22</p>
                      <p class="item__tel"><span>TEL</span>029-263-5207</p>
                    </div>
                  </div>
                </div>
              </section>

              <section class="manufacturer__category category">
                <h3 class="category__name">諸工芸品</h3>
                <div class="item">
                  <p class="item__id">26</p>
                  <h4 class="item__name">【手描き鯉のぼり】</h4>
                  <div class="item__box">
                    <p class="item__company">株式会社三國屋 ｜ 藤田 昌平</p>
                    <div class="item__info">
                      <p class="item__address">北茨城市大津町北町3341-1</p>
                      <p class="item__tel"><span>TEL</span>0293-46-1836</p>
                    </div>
                  </div>
                </div>
                <div class="item">
                  <p class="item__id">27</p>
                  <h4 class="item__name">【線香】</h4>
                  <div class="item__box">
                    <p class="item__company" data-show-icon>駒村清明堂 ｜ 駒村 道廣</p>
                    <div class="item__info">
                      <p class="item__address">石岡市小幡1899</p>
                      <p class="item__tel"><span>TEL</span>0299-42-2819</p>
                    </div>
                  </div>
                  <div class="item__box">
                    <p class="item__company" data-show-icon>株式会社ニックン紫山堂 ｜ 藤田 操</p>
                    <div class="item__info">
                      <p class="item__address">石岡市小幡1632-8</p>
                      <p class="item__tel"><span>TEL</span>0299-42-3702</p>
                    </div>
                  </div>
                </div>
                <div class="item">
                  <p class="item__id">27</p>
                  <h4 class="item__name">【浮世絵手摺木版画】</h4>
                  <div class="item__box">
                    <p class="item__company" data-show-icon>渡辺木版 ｜ 渡辺 和夫</p>
                    <div class="item__info">
                      <p class="item__address">常総市新石下4001-7</p>
                      <p class="item__tel"><span>TEL</span>0297-42-2337</p>
                    </div>
                  </div>
                </div>
                <div class="item">
                  <p class="item__id">29</p>
                  <h4 class="item__name">【真壁石燈籠】</h4>
                  <div class="item__box">
                    <p class="item__company" data-show-icon>真壁石材協同組合</p>
                    <div class="item__info">
                      <p class="item__address">桜川市真壁町真壁402</p>
                      <p class="item__tel"><span>TEL</span>0296-55-2535</p>
                    </div>
                  </div>
                </div>
                <div class="item">
                  <p class="item__id">30</p>
                  <h4 class="item__name">【べっ甲細工】</h4>
                  <div class="item__box">
                    <p class="item__company" data-show-icon>鼈甲屋かりべ ｜ 刈部 博</p>
                    <div class="item__info">
                      <p class="item__address">桜川市真壁町田1035-2</p>
                      <p class="item__tel"><span>TEL</span>0296-54-1140</p>
                    </div>
                  </div>
                </div>
                <div class="item">
                  <p class="item__id">31</p>
                  <h4 class="item__name">【梵鐘】</h4>
                  <div class="item__box">
                    <p class="item__company" data-show-icon>小田部鋳造株式会社<br>小田部 庄右衛門</p>
                    <div class="item__info">
                      <p class="item__address">桜川市真壁町田45</p>
                      <p class="item__tel"><span>TEL</span>0296-55-0066</p>
                    </div>
                  </div>
                </div>
                <div class="item">
                  <p class="item__id">32</p>
                  <h4 class="item__name">【米粒人形】</h4>
                  <div class="item__box">
                    <p class="item__company">岡崎 ゆき子</p>
                    <div class="item__info">
                      <p class="item__address">水戸市渡里町3234-10</p>
                      <p class="item__tel"><span>TEL</span>029-226-8462</p>
                    </div>
                  </div>
                </div>
                <div class="item">
                  <p class="item__id">33</p>
                  <h4 class="item__name">【大穂のほうき】</h4>
                  <div class="item__box">
                    <p class="item__company">大穂帚協同組合（株式会社中野産業）</p>
                    <div class="item__info">
                      <p class="item__address">つくば市前野347-2</p>
                      <p class="item__tel"><span>TEL</span>029-864-0368</p>
                    </div>
                  </div>
                </div>
                <div class="item">
                  <p class="item__id">34</p>
                  <h4 class="item__name">【武道具】</h4>
                  <div class="item__box">
                    <p class="item__company">水戸東武武道具製作所 ｜ 高山 能昌</p>
                    <div class="item__info">
                      <p class="item__address">水戸市北見町4-8</p>
                      <p class="item__tel"><span>TEL</span>029-221-5066</p>
                    </div>
                  </div>
                </div>
                <div class="item">
                  <p class="item__id">35</p>
                  <h4 class="item__name">【カガミクリスタル】</h4>
                  <div class="item__box">
                    <p class="item__company" data-show-icon>カガミクリスタル株式会社</p>
                    <div class="item__info">
                      <p class="item__address">龍ケ崎市向陽台4-5</p>
                      <p class="item__tel"><span>TEL</span>0297-64-7111</p>
                    </div>
                  </div>
                </div>
                <div class="item">
                  <p class="item__id">36</p>
                  <h4 class="item__name">【淡水真珠】</h4>
                  <div class="item__box">
                    <p class="item__company">明恒パール牛久観光株式会社</p>
                    <div class="item__info">
                      <p class="item__address">牛久市猪子町4</p>
                      <p class="item__tel"><span>TEL</span>029-874-0001</p>
                    </div>
                  </div>
                </div>
                <div class="item">
                  <p class="item__id">37</p>
                  <h4 class="item__name">【あやめ笠】</h4>
                  <div class="item__box">
                    <p class="item__company">（一社）潮来市シルバー人材センター</p>
                    <div class="item__info">
                      <p class="item__address">潮来市辻765</p>
                      <p class="item__tel"><span>TEL</span>0299-63-1213</p>
                    </div>
                  </div>
                </div>
                <div class="item">
                  <p class="item__id">38</p>
                  <h4 class="item__name">【繁昌笠】</h4>
                  <div class="item__box">
                    <p class="item__company">寺内 泰夫</p>
                    <div class="item__info">
                      <p class="item__address">行方市繁昌1183-5</p>
                      <p class="item__tel"><span>TEL</span>0291-35-2242</p>
                    </div>
                  </div>
                </div>
                <div class="item">
                  <p class="item__id">39</p>
                  <h4 class="item__name">【結城まゆ工芸】</h4>
                  <div class="item__box">
                    <p class="item__company" data-show-icon>市村まゆ工房 ｜ 市村 マツ</p>
                    <div class="item__info">
                      <p class="item__address">結城市結城13596</p>
                      <p class="item__tel"><span>TEL</span>0296-32-9957</p>
                    </div>
                  </div>
                </div>
                <div class="item">
                  <p class="item__id">40</p>
                  <h4 class="item__name">【那珂湊だるま】</h4>
                  <div class="item__box">
                    <p class="item__company">だるまや ｜  飯田 隆司</p>
                    <div class="item__info">
                      <p class="item__address">ひたちなか市十三奉行1975</p>
                      <p class="item__tel"><span>TEL</span>029-262-3725</p>
                    </div>
                  </div>
                </div>
                <div class="item">
                  <p class="item__id">41</p>
                  <h4 class="item__name">【霞ヶ浦帆引き船模型】</h4>
                  <div class="item__box">
                    <p class="item__company">霞ヶ浦帆引き船・帆引き網漁法保存会</p>
                    <div class="item__info">
                      <p class="item__address">かすみがうら市坂1029-1</p>
                      <p class="item__tel"><span>TEL</span>029-896-0017</p>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <a class="manufacturer__map" href="https://www.google.com/maps/d/edit?mid=1tdnDS4BjVu2uQ3h1lKRL4l5euLy7A8k&usp=sharing" target="_blank">
              <img src="/dist/images/detail/arrow.svg">マップを見る
            </a>
          </div>
        </div>
      </article>
    </main>
  </transition>
</template>

<script>
import initPs from "../mixin/init-ps"

export default {
  mixins: [
    initPs
  ]
  // TODO: 一覧の動的な読み込みに対応する
}
</script>

<style lang="scss" scoped>
@import "../scss/variable.scss";

.manufacturer {
  position: relative;
  &__remarks {
    display: flex;
    align-items: center;
    @media (max-width:768px) {
      align-items: flex-start;
    }
    &:before {
      content: "";
      order: -2;
      min-width: 2rem;
      height: 2em;
      background: url(/dist/images/common/logo-product-small.svg) no-repeat center/ contain;
      @media (max-width:768px) {
        min-width: 1rem;
      }
    }
    &:after {
      content: "";
      order: -1;
      flex-basis: 2rem; 
      margin: 0 .5rem;
      height: 1px;
      border-top: 1px dashed #000;
      @media (max-width:768px) {
        margin-top: .75rem;
      }
    }
  }
  &__grid {
    margin-bottom: 4rem;
  }
  &__category {
    &:not(:last-child) {
      margin-bottom: 6rem;
    }
  }
  &__map {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .5rem;
    width: 100%;
    height: 4rem;
    border: 1px solid $gray-light;
    border-radius: 999px;
  }
}

.category {
  &__name {
    margin-bottom: 1rem;
    color: $black;
    font-size: 1.5rem;
  }
  .item:not(:last-child) {
    margin-bottom: 2rem;
  }
}

.item {
  position: relative;
  padding-left: 2rem;
  &__id {
    position: absolute;
    left: 0;
    top: 0;
    font-size: 1.25rem;
    line-height: 1;
  }
  &__box {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    color: $gray;
    @media (max-width:768px) {
      padding-bottom: .5rem;
      border-bottom: 1px solid $gray-light;
    }
    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }
  &__name {
    margin-right: auto;
    margin-bottom: .5rem;
    color: $gray;
    font-feature-settings: "palt";
    @media (max-width:768px) {
      margin-bottom: .75rem;
    }
  }
  &__info {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: flex-end;
    gap: .25rem;
    font-size: .875rem;
    @media (max-width:768px) {
      align-items: flex-start;
      font-size: 1rem;
    }
  }
  &__company {
    display: flex;
    align-items: center;
    margin-bottom: .5rem;
    text-align: right;
    font-feature-settings: "palt";
    @media (max-width:768px) {
      display: block;
      text-align: left;
    }
    &:before {
      content: "";
      flex-grow: 1;
      margin-right: .5rem;
      min-width: 2rem;
      height: 1px;
      background-color: $gray-light;
      @media (max-width:768px) {
        display: none;
      }
    }
    &[data-show-icon]:after {
      content: "";
      margin-left: .5rem;
      width: 1em;
      height: 1.5em;
      background: url(/dist/images/common/logo-product-small.svg) no-repeat center/ contain;
      @media (max-width:768px) {
        display: inline-block;
        height: 1em;
      }
    }
  }
  &__tel,
  &__web {
    display: flex;
    align-items: center;
  }
  &__tel > span,
  &__web > span {
    padding: .125rem;
    background-color: $gray;
    margin: 0 .5rem 0 1rem;
    color: #fff;
    font-size: .75rem;
    line-height: 1;
    @media (max-width:768px) {
      margin-left: 0;
    }
  }
  &__web > a {
    text-decoration: underline;
  }
}
</style>