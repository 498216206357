<template>
  <transition name="fade" mode="out-in" appear>
    <main class="c-main">
      <article ref="ps" class="c-main__content js-ps">
        <div class="c-main__single">
          <transition name="fade" mode="out-in" appear>
            <div>
              <div class="background" style="background-image: url(/dist/images/home/scene-special1.jpg)"></div>
              <p class="pagename">特集｜つくり手を訪ねて</p>
              
              <div class="special">
                <div class="share">
                  <a href="" class="share__icon"><i class="fab fa-facebook-f"></i></a>
                  <a href="" class="share__icon"><i class="fas fa-share-alt"></i></a>
                </div>

                <h2 class="product">西ノ内和紙<span>［常陸大宮市］</span></h2>
                <h3 class="catch">奥久慈の自然が<br>最高の原料と<br>手漉きの技を支える</h3>
                <h4 class="name">菊池 大輔さん</h4>

                <section class="content">
                  <div class="content__block">
                    <p class="content__text">常陸大宮市旧山方町の西ノ内地区に江戸時代から伝わる西ノ内和紙は、最高級の那須楮を原料とし、奥久慈の清らかな水で漉き出される国・県の無形文化財。丈夫で水に強いことから庶民の日用品として重宝され、徳川光圀が編纂した「大日本史」や商家の帳簿「大福帳」にも用いられてきた。その伝統を継承する「紙のさと」の四代目、菊池大輔さんは、小佐畑さんが全面的な信頼を寄せる職人の一人。家族で那須楮の栽培から紙漉きまでを一貫して担い、楮の皮剥ぎから始まる地道な製造工程をすべて手作業で行っている。</p>
                    <p class="content__text">「昔は多くの農家が農閑期に紙を作っていましたが、現在市内に残っている工房はうちを含めて僅か2軒。厳しさもありますが、ものや技法は目に見える形で伝えていかなければ、やがて廃れてしまいます。この土地ならではの原料や技術で作り続けることで、西ノ内和紙という伝統を後世に残していきたい」と菊池さん。数年前から小佐畑さんをはじめとする県内の工芸士とのコラボレーションにも力を入れ、互いの作業場を行き来するなかで、作品づくりに最適な紙を漉く努力を重ねてきた。</p>
                    <p class="content__text">「和紙の良さを決めるのは私ではなくお客様」と言い切る菊池さん。「それぞれの用途に最適な和紙を届けるために、どんな細かい注文にも応えたいですし、新しい試みにも挑戦していきたい。紙は使われてこそ生きるものですから」</p>
                    <figure class="content__img">
                      <img src="/dist/images/home/scene-bg1.jpg" alt="">
                      <figcaption class="content__caption">印刷物とは写真品質・文章内容等異なる場合がございますのであらかじめご了承ください。</figcaption>
                    </figure>
                  </div>
                  <div class="content__block">
                    <p class="content__text">常陸大宮市旧山方町の西ノ内地区に江戸時代から伝わる西ノ内和紙は、最高級の那須楮を原料とし、奥久慈の清らかな水で漉き出される国・県の無形文化財。丈夫で水に強いことから庶民の日用品として重宝され、徳川光圀が編纂した「大日本史」や商家の帳簿「大福帳」にも用いられてきた。その伝統を継承する「紙のさと」の四代目、菊池大輔さんは、小佐畑さんが全面的な信頼を寄せる職人の一人。家族で那須楮の栽培から紙漉きまでを一貫して担い、楮の皮剥ぎから始まる地道な製造工程をすべて手作業で行っている。</p>
                    <p class="content__text">「昔は多くの農家が農閑期に紙を作っていましたが、現在市内に残っている工房はうちを含めて僅か2軒。厳しさもありますが、ものや技法は目に見える形で伝えていかなければ、やがて廃れてしまいます。この土地ならではの原料や技術で作り続けることで、西ノ内和紙という伝統を後世に残していきたい」と菊池さん。数年前から小佐畑さんをはじめとする県内の工芸士とのコラボレーションにも力を入れ、互いの作業場を行き来するなかで、作品づくりに最適な紙を漉く努力を重ねてきた。</p>
                    <p class="content__text">「和紙の良さを決めるのは私ではなくお客様」と言い切る菊池さん。「それぞれの用途に最適な和紙を届けるために、どんな細かい注文にも応えたいですし、新しい試みにも挑戦していきたい。紙は使われてこそ生きるものですから」</p>
                    <figure class="content__img">
                      <img src="/dist/images/home/scene-bg1.jpg" alt="">
                      <figcaption class="content__caption">印刷物とは写真品質・文章内容等異なる場合がございますのであらかじめご了承ください。</figcaption>
                    </figure>
                  </div>
                  <div class="content__block">
                    <p class="content__text">常陸大宮市旧山方町の西ノ内地区に江戸時代から伝わる西ノ内和紙は、最高級の那須楮を原料とし、奥久慈の清らかな水で漉き出される国・県の無形文化財。丈夫で水に強いことから庶民の日用品として重宝され、徳川光圀が編纂した「大日本史」や商家の帳簿「大福帳」にも用いられてきた。その伝統を継承する「紙のさと」の四代目、菊池大輔さんは、小佐畑さんが全面的な信頼を寄せる職人の一人。家族で那須楮の栽培から紙漉きまでを一貫して担い、楮の皮剥ぎから始まる地道な製造工程をすべて手作業で行っている。</p>
                    <p class="content__text">「昔は多くの農家が農閑期に紙を作っていましたが、現在市内に残っている工房はうちを含めて僅か2軒。厳しさもありますが、ものや技法は目に見える形で伝えていかなければ、やがて廃れてしまいます。この土地ならではの原料や技術で作り続けることで、西ノ内和紙という伝統を後世に残していきたい」と菊池さん。数年前から小佐畑さんをはじめとする県内の工芸士とのコラボレーションにも力を入れ、互いの作業場を行き来するなかで、作品づくりに最適な紙を漉く努力を重ねてきた。</p>
                    <p class="content__text">「和紙の良さを決めるのは私ではなくお客様」と言い切る菊池さん。「それぞれの用途に最適な和紙を届けるために、どんな細かい注文にも応えたいですし、新しい試みにも挑戦していきたい。紙は使われてこそ生きるものですから」</p>
                    <figure class="content__img">
                      <img src="/dist/images/home/scene-bg1.jpg" alt="">
                      <figcaption class="content__caption">印刷物とは写真品質・文章内容等異なる場合がございますのであらかじめご了承ください。</figcaption>
                    </figure>
                  </div>
                </section>

                <h3>PICK UP</h3>
                <div class="pickup">
                  <Item
                    v-for="i in 3"
                    :item="{
                      post_id: 44,
                      name: '西ノ内和紙',
                      en_name: 'Nishi-no-Uchi Washi',
                      picture: 'http://demo.tokino.co.jp/wpkanri/wp-content/uploads/2018/03/16-jppaper-stationery-nishinouchiwashi.jpg'
                    }"
                    class="pickup__item"
                    delay="0"
                  ></Item>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </article>

      <SideList class="c-main__side js-ps"></SideList>
    </main>
  </transition>
</template>

<script>
import SideList from "../components/side-list.vue"
import Item from "../components/item.vue"
import initPs from "../mixin/init-ps"

export default {
  components: { SideList, Item }, 
  mixins: [ initPs ],

  data: () => ({
    page: 1
  }),

  methods: {
    paginate(num, event) {
      if (this.$refs.firstPage) this.$refs.firstPage.classList.remove('is-current')
      this.page = num
      this.$refs.firstPage = event.target
      this.$refs.firstPage.classList.add('is-current')
      this.scroll()
    },
    scroll() {
      if (window.matchMedia("(min-width:769px)").matches) {
        this.$refs.ps.scrollTop = 0
      } else {
        window.scroll(0, 0)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../scss/variable.scss";

.c-main {
  @media (max-width:768px) {
    flex-direction: column;
  }
}

.c-main__content {
  @media (max-width:768px) {
    padding-top: 5rem;
    padding-bottom: 100px;
  }
}

.c-main__single {
  width: 100%;
  height: 100%;
}

.background {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 20rem;
  background-position: center;
  background-size: cover;
}

.pagename {
  position: relative;
  margin-right: 3rem;
  color: #fff;
  font-size: 1.25rem;
  text-align: right;
  @media (max-width:768px) {
    margin: 3rem auto 4rem;
    text-align: center;
  }
}

.special {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 5rem -20px 0 0;
  padding: 2rem 10rem 3rem 3rem;
  font-family: "Ryumin Medium KL", serif;
  background: #fff;
  @media (max-width:768px) {
    margin: 0 -2rem;
    padding: 1rem 2rem 2rem;
  }
  &::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 100%;
    width: 1rem;
    height: 1rem;
    border: .5rem solid transparent;
    border-bottom-color: #fff;
    border-right-color: #fff;
  }
  &::after {
    content: "";
    position: absolute;
    left: 1rem;
    bottom: 100%;
    width: calc(100% - 1rem);
    height: 1rem;
    background: #fff;
  }
}

.share {
  position: absolute;
  right: 3rem;
  top: 2rem;
  @media (max-width: 768px) {
    order: -1;
    position: static;
    margin-bottom: 3rem;
    text-align: right;
  }
}

.share__icon {
  border-left: 1px solid #000;
  padding: .25rem .5rem;
  &:last-child {
    @media (max-width: 768px) {
      border-right: 1px solid #000;
    }
  }
}

.catch {
  order: -1;
  margin-bottom: 2rem;
  font-size: 1.5rem;
  @media (max-width: 768px) {
    text-align: center;
  }
}

.product {
  margin-bottom: 1rem;
  font-size: 2rem;
  text-align: center;
}

.product > span {
  display: block;
  font-size: .5em;
}

.name {
  margin-bottom: 3rem;
  font-size: 1.25em;
  text-align: center;
  font-feature-settings: "palt";
}

.content {
  display: flex;
  flex-direction: column;
  gap: 5rem;
  margin-bottom: 5rem;
}

.content__block {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.content__text {
  line-height: 2;
  text-align: justify;
}

.content__caption {
  font-size: .875rem;
}

.pickup {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  gap: 2rem 3rem;
  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.pickup__item {
  flex: 1;
  height: 15rem;
  @media (max-width: 768px) {
    flex: auto;
  }
}

.pickup__bg {
  display: block;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  transition: .3s ease;
  @at-root {
    .item:hover &,
    .item.is-hover & {
      transform: scale(1.1);
    }
  }
}
</style>