
<template>
  <footer class="footer">
    <figure class="footer__logo logo">
      <img class="logo__mark" src="/dist/images/common/logo-product.svg" alt="茨城県伝統工芸品">
      <figucaption class="logo__caption">茨城県伝統工芸品</figucaption>
    </figure>
    <div class="footer__info">
      <span class="footer__contactus">お問い合わせ</span>
      <span class="footer__name">茨城県産業戦略部技術振興局技術革新課 地場産業振興室</span>
      <address class="footer__address">〒310-8555 茨城県水戸市笠原町978番地6</address>
      <a href="tel:0293013584" class="footer__tel">TEL.029-301-3585</a>
      <router-link to="/privacy/" class="footer__link">個人情報保護方針</router-link>
    </div>
    <small class="footer__copyright c-show" data-show="sp">Copylight(c) 2023 Ibaraki pref. All Rights Reserved.</small>
  </footer>
</template>

<script> 
  export default {
    
  }
</script>

<style lang="scss" scoped>
  @import "../scss/variable.scss";
  
  .footer {
    position: relative;
    display: flex;
    align-items: center;
    height: 4rem;
    padding: 0 2rem;
    font-size: .875rem;
    letter-spacing: .1em;
    @media (max-width:768px) {
      flex-direction: column;
      padding: 3rem 2rem 2rem;
      height: auto;
      border-top: 1px solid rgba(0,0,0,0.1);
      text-align: center;
    }
    &__logo {
      display: none;
      @media (max-width:768px) {
        position: static;
        display: block;
        width: 100%;
        margin-bottom: 2rem;
        padding-bottom: 2rem;
        border-bottom: 1px solid rgba(0,0,0,0.1);
      }
    }
    &__info {
      font-size: 0;
    }
    &__contactus,
    &__name,
    &__address,
    &__tel,
    &__link {
      display: inline;
      font-size: .875rem;
      @media (max-width:768px) {
        display: block;
      }
      &:not(.footer__link):after {
        content: "/";
        display: inline-block;
        margin: 0 .5rem;
        @media (max-width:768px) {
          display: none;
        }
      }
    }
    &__contactus {
      @media (max-width:768px) {
        margin-bottom: 1rem;
        font-family: $weight-bold;
        font-size: 1.25rem;
      }
    }
    &__name {
      @media (max-width:768px) {
        font-weight: bold;
        font-size: 1rem;
      }
    }
    &__address {
      font-style: normal;
      @media (max-width:768px) {
        margin: .5rem 0 0rem;
      }
    }
    &__copyright {
      @media (max-width:768px) {
        margin-top: 2rem;
      }
    }
    &__link {
      text-decoration: underline;
    }
  }

  .logo {
    @media (max-width:768px) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    &__mark {
      @media (max-width:768px) {
        width: 4rem;
        margin-bottom: 1rem;
      }
    }
    &__caption {
      @media (max-width:768px) {
        font-size: 1.25rem;
        font-family: $weight-bold;
      }
    }
  }
</style>