import axios from "axios"

export default {
  debug: true,
  state: {
    items: [],
    categorise: []
  },

  getData () {
    // const json = [
    //   {
    //     "name": "霞ヶ浦帆引き船模型",
    //     "en_name": "Model of Hobikisen",
    //     "picture": "http://www.teshigoto-ibaraki.com/wpkanri/wp-content/uploads/2018/03/41-variouscrafts-modelhobikisen-300x225.jpg",
    //     "post_id": 92,
    //     "category": "諸工芸品"
    //   },
    //   {
    //     "name": "那珂湊だるま",
    //     "en_name": "Daruma doll",
    //     "picture": "http://www.teshigoto-ibaraki.com/wpkanri/wp-content/uploads/2018/03/40-variouscrafts-darumadoll-300x225.jpg",
    //     "post_id": 91,
    //     "category": "諸工芸品"
    //   },
    //   {
    //     "name": "結城まゆ工芸",
    //     "en_name": "Silk Cocoon Craft",
    //     "picture": "http://www.teshigoto-ibaraki.com/wpkanri/wp-content/uploads/2018/03/39-variouscrafts-silkcocoon-300x225.jpg",
    //     "post_id": 90,
    //     "category": "諸工芸品"
    //   },
    //   {
    //     "name": "繁昌笠",
    //     "en_name": "Hanjogasa",
    //     "picture": "http://www.teshigoto-ibaraki.com/wpkanri/wp-content/uploads/2018/03/38-variouscrafts-hanojogasa-300x225.jpg",
    //     "post_id": 89,
    //     "category": "諸工芸品"
    //   },
    //   {
    //     "name": "あやめ笠",
    //     "en_name": "Iris Hat",
    //     "picture": "http://www.teshigoto-ibaraki.com/wpkanri/wp-content/uploads/2018/03/37-variouscrafts-irishat-300x225.jpg",
    //     "post_id": 88,
    //     "category": "諸工芸品"
    //   },
    //   {
    //     "name": "淡水真珠",
    //     "en_name": "Freshwater Pearls",
    //     "picture": "http://www.teshigoto-ibaraki.com/wpkanri/wp-content/uploads/2018/03/36-variouscrafts-pearls-300x225.jpg",
    //     "post_id": 73,
    //     "category": "諸工芸品"
    //   },
    //   {
    //     "name": "カガミクリスタル",
    //     "en_name": "Kagami Crystal",
    //     "picture": "http://www.teshigoto-ibaraki.com/wpkanri/wp-content/uploads/2018/03/35-variouscrafts-kagamicrystal-300x225.jpg",
    //     "post_id": 93,
    //     "category": "諸工芸品"
    //   },
    //   {
    //     "name": "武道具",
    //     "en_name": "Budogu",
    //     "picture": "http://www.teshigoto-ibaraki.com/wpkanri/wp-content/uploads/2018/03/34-variouscrafts-budogu-300x225.jpg",
    //     "post_id": 72,
    //     "category": "諸工芸品"
    //   },
    //   {
    //     "name": "大穂のほうき",
    //     "en_name": "Oho Broom",
    //     "picture": "http://www.teshigoto-ibaraki.com/wpkanri/wp-content/uploads/2018/03/33-variouscrafts-ohobroom-300x225.jpg",
    //     "post_id": 71,
    //     "category": "諸工芸品"
    //   },
    //   {
    //     "name": "米粒人形",
    //     "en_name": "Rice Grain Doll",
    //     "picture": "http://www.teshigoto-ibaraki.com/wpkanri/wp-content/uploads/2018/03/32-variouscrafts-ricedoll-300x225.jpg",
    //     "post_id": 70,
    //     "category": "諸工芸品"
    //   },
    //   {
    //     "name": "梵鐘",
    //     "en_name": "Buddhist Bell",
    //     "picture": "http://www.teshigoto-ibaraki.com/wpkanri/wp-content/uploads/2018/03/31-variouscrafts-buddhistbell-300x225.jpg",
    //     "post_id": 69,
    //     "category": "諸工芸品"
    //   },
    //   {
    //     "name": "べっ甲細工",
    //     "en_name": "Tortoise-Shell Artwork",
    //     "picture": "http://www.teshigoto-ibaraki.com/wpkanri/wp-content/uploads/2018/03/30-variouscrafts-tortoiseshell-300x225.jpg",
    //     "post_id": 68,
    //     "category": "諸工芸品"
    //   },
    //   {
    //     "name": "真壁石燈籠",
    //     "en_name": "Makabe Stone Lantern",
    //     "picture": "http://www.teshigoto-ibaraki.com/wpkanri/wp-content/uploads/2018/03/29-variouscrafts-stonelantern-300x225.jpg",
    //     "post_id": 67,
    //     "category": "諸工芸品"
    //   },
    //   {
    //     "name": "浮世絵手摺木版画",
    //     "en_name": "Ukiyo-e",
    //     "picture": "http://www.teshigoto-ibaraki.com/wpkanri/wp-content/uploads/2018/03/28-variouscrafts-ukiyoe-300x225.jpg",
    //     "post_id": 66,
    //     "category": "諸工芸品"
    //   },
    //   {
    //     "name": "線香",
    //     "en_name": "Incense",
    //     "picture": "http://www.teshigoto-ibaraki.com/wpkanri/wp-content/uploads/2018/03/27-variouscrafts-incense-300x225.jpg",
    //     "post_id": 65,
    //     "category": "諸工芸品"
    //   },
    //   {
    //     "name": "手描き鯉のぼり",
    //     "en_name": "Maiwai, Fishermen's Flag",
    //     "picture": "http://www.teshigoto-ibaraki.com/wpkanri/wp-content/uploads/2018/03/26-variouscrafts-koinobori-300x225.jpg",
    //     "post_id": 62,
    //     "category": "諸工芸品"
    //   },
    //   {
    //     "name": "万祝・大漁旗",
    //     "en_name": "Maiwai, Fishermen's Flag",
    //     "picture": "http://www.teshigoto-ibaraki.com/wpkanri/wp-content/uploads/2018/03/25-festival-utensils-mawaiflag-300x225.jpg",
    //     "post_id": 58,
    //     "category": "祭礼・用具"
    //   },
    //   {
    //     "name": "桂雛",
    //     "en_name": "Katsura Hina Dolls",
    //     "picture": "http://www.teshigoto-ibaraki.com/wpkanri/wp-content/uploads/2018/03/24-festival-utensils-hinadolls-300x225.jpg",
    //     "post_id": 57,
    //     "category": "祭礼・用具"
    //   },
    //   {
    //     "name": "水府提灯",
    //     "en_name": "Suifu Chochin",
    //     "picture": "http://www.teshigoto-ibaraki.com/wpkanri/wp-content/uploads/2018/03/23-festival-utensils-suifuchochin-300x225.jpg",
    //     "post_id": 56,
    //     "category": "祭礼・用具"
    //   },
    //   {
    //     "name": "常陸獅子",
    //     "en_name": "Hitachijishi",
    //     "picture": "http://www.teshigoto-ibaraki.com/wpkanri/wp-content/uploads/2018/03/22-festival-utensils-hitachijishi-300x225.jpg",
    //     "post_id": 54,
    //     "category": "祭礼・用具"
    //   },
    //   {
    //     "name": "水海道染色村きぬの染",
    //     "en_name": "Silk Dyeing in Mitsukaido",
    //     "picture": "http://www.teshigoto-ibaraki.com/wpkanri/wp-content/uploads/2018/03/21-dyed-silkdyeing-300x225.jpg",
    //     "post_id": 51,
    //     "category": "織物・染物"
    //   },
    //   {
    //     "name": "いしげ結城紬",
    //     "en_name": "Ishige Yuki-Tsumugi",
    //     "picture": "http://www.teshigoto-ibaraki.com/wpkanri/wp-content/uploads/2018/03/20-dyed-ishigeyukitsumugi-300x225.jpg",
    //     "post_id": 50,
    //     "category": "織物・染物"
    //   },
    //   {
    //     "name": "本場結城紬",
    //     "en_name": "Yuki-Tsumugi",
    //     "picture": "http://www.teshigoto-ibaraki.com/wpkanri/wp-content/uploads/2018/03/19-dyed-yukitsumugi-300x225.jpg",
    //     "post_id": 105,
    //     "category": "織物・染物"
    //   },
    //   {
    //     "name": "国寿石大子硯",
    //     "en_name": "Kokujuseki Daigo Suzuri",
    //     "picture": "http://www.teshigoto-ibaraki.com/wpkanri/wp-content/uploads/2018/03/18-jppaper-stationery-kokujusekisuzuri-300x225.jpg",
    //     "post_id": 48,
    //     "category": "和紙・文具"
    //   },
    //   {
    //     "name": "かな料紙",
    //     "en_name": "Kana Ryoushi",
    //     "picture": "http://www.teshigoto-ibaraki.com/wpkanri/wp-content/uploads/2018/03/17-jppaper-stationery-kanaryoushi-300x225.jpg",
    //     "post_id": 46,
    //     "category": "和紙・文具"
    //   },
    //   {
    //     "name": "西ノ内和紙",
    //     "en_name": "Nishi-no-Uchi Washi",
    //     "picture": "http://www.teshigoto-ibaraki.com/wpkanri/wp-content/uploads/2018/03/16-jppaper-stationery-nishinouchiwashi-300x225.jpg",
    //     "post_id": 44,
    //     "category": "和紙・文具"
    //   },
    //   {
    //     "name": "古河竹工画",
    //     "en_name": "Koga Bamboo Pictures",
    //     "picture": "http://www.teshigoto-ibaraki.com/wpkanri/wp-content/uploads/2018/03/15-bamboowork-bamboopictures-300x225.jpg",
    //     "post_id": 40,
    //     "category": "竹工品"
    //   },
    //   {
    //     "name": "雪村うちわ",
    //     "en_name": "Sesson Uchiwa",
    //     "picture": "http://www.teshigoto-ibaraki.com/wpkanri/wp-content/uploads/2018/03/14-bamboowork-sessonuchiwa-300x225.jpg",
    //     "post_id": 38,
    //     "category": "竹工品"
    //   },
    //   {
    //     "name": "竹矢",
    //     "en_name": "Takeya, Bamboo Arrow",
    //     "picture": "http://www.teshigoto-ibaraki.com/wpkanri/wp-content/uploads/2018/03/13-bamboowork-takeya-300x225.jpg",
    //     "post_id": 36,
    //     "category": "竹工品"
    //   },
    //   {
    //     "name": "茨城籐工芸",
    //     "en_name": "Rattan Craft",
    //     "picture": "http://www.teshigoto-ibaraki.com/wpkanri/wp-content/uploads/2018/03/12-bamboowork-rattancraft-300x225.jpg",
    //     "post_id": 34,
    //     "category": "籐工芸"
    //   },
    //   {
    //     "name": "石岡府中杉細工",
    //     "en_name": "Japanese Cedar Handiwork in Fuchu, Ishioka",
    //     "picture": "http://www.teshigoto-ibaraki.com/wpkanri/wp-content/uploads/2018/03/11-woodwork-cedarhandiworks-300x225.jpg",
    //     "post_id": 31,
    //     "category": "木工品"
    //   },
    //   {
    //     "name": "涸沼竿",
    //     "en_name": "Hinuma Rod",
    //     "picture": "http://www.teshigoto-ibaraki.com/wpkanri/wp-content/uploads/2018/03/10-woodwork-hinumarod-300x225.jpg",
    //     "post_id": 29,
    //     "category": "木工品"
    //   },
    //   {
    //     "name": "とよさとの桶・樽",
    //     "en_name": "Toyosato-no-Oke",
    //     "picture": "http://www.teshigoto-ibaraki.com/wpkanri/wp-content/uploads/2018/03/09-woodwork-toyosatooke-300x225.jpg",
    //     "post_id": 28,
    //     "category": "木工品"
    //   },
    //   {
    //     "name": "水戸やなかの桶",
    //     "en_name": "Mito Yanaka-no-Oke",
    //     "picture": "http://www.teshigoto-ibaraki.com/wpkanri/wp-content/uploads/2018/03/08-woodwork-mitoyanakaoke-300x225.jpg",
    //     "post_id": 26,
    //     "category": "木工品"
    //   },
    //   {
    //     "name": "結城桐箪笥",
    //     "en_name": "Kiritansu in Yuki",
    //     "picture": "http://www.teshigoto-ibaraki.com/wpkanri/wp-content/uploads/2018/03/07-woodwork-kiritansu-300x225.jpg",
    //     "post_id": 24,
    //     "category": "木工品"
    //   },
    //   {
    //     "name": "結城地方の桐下駄",
    //     "en_name": "Kirigeta in Yuki",
    //     "picture": "http://www.teshigoto-ibaraki.com/wpkanri/wp-content/uploads/2018/03/06-woodwork-kirigeta-300x225.jpg",
    //     "post_id": 22,
    //     "category": "木工品"
    //   },
    //   {
    //     "name": "八溝塗",
    //     "en_name": "Yamizonuri",
    //     "picture": "http://www.teshigoto-ibaraki.com/wpkanri/wp-content/uploads/2018/03/05-woodwork-yamizonuri-300x225.jpg",
    //     "post_id": 20,
    //     "category": "木工品"
    //   },
    //   {
    //     "name": "粟野春慶塗",
    //     "en_name": "Awano Shunkeinuri",
    //     "picture": "http://www.teshigoto-ibaraki.com/wpkanri/wp-content/uploads/2018/03/04-woodwork-awano-300x225.jpg",
    //     "post_id": 17,
    //     "category": "木工品"
    //   },
    //   {
    //     "name": "五浦天心焼",
    //     "en_name": "Izuratenshin-yaki",
    //     "picture": "http://www.teshigoto-ibaraki.com/wpkanri/wp-content/uploads/2018/03/03-pottery-izuratenshin-300x225.jpg",
    //     "post_id": 15,
    //     "category": "陶器"
    //   },
    //   {
    //     "name": "つくばね焼",
    //     "en_name": "Tsukubane-yaki",
    //     "picture": "http://www.teshigoto-ibaraki.com/wpkanri/wp-content/uploads/2018/03/02-pottery-tsukubaneyaki-300x225.jpg",
    //     "post_id": 13,
    //     "category": "陶器"
    //   },
    //   {
    //     "name": "笠間焼",
    //     "en_name": "Kasama-yaki",
    //     "picture": "http://www.teshigoto-ibaraki.com/wpkanri/wp-content/uploads/2018/03/01-pottery-kasamayaki-300x225.jpg",
    //     "post_id": 1,
    //     "category": "陶器"
    //   }
    // ].reverse()

    axios.get("http://demo.tokino.co.jp/wpkanri/").then(response => {
      const json = response.data.reverse()

      for (let i = 0; i < json.length; i++) {
        const element = json[i]
          this.state.items.push(element)
      }

      let category = json[0].category
      let array = []
      for (let i = 0; i < json.length; i++) {
        const element = json[i]
        if (element.category !== category) {
          this.state.categorise.push(array)
          category = element.category
          array = []
        }
        array.push(element)

        if (i == json.length - 1) {
          this.state.categorise.push(array)
        }
      }
    })
  }
}