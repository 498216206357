export default {
  mounted () {
    if (!window.matchMedia('(min-width:769px)').matches) return

    const ps = document.getElementsByClassName('js-ps')
    for (let i = 0; i < ps.length; i++) {
      const element = ps[i]
      new PerfectScrollbar(element, {
        wheelSpeed: 5,
        minScrollbarLength: 20,
        suppressScrollX: true,
        wheelPropagation: true
      })
    }
  }
}