<template>
  <figure class="loader">
    <img src="/dist/images/common/loader.gif" alt="読み込み中">
  </figure>
</template>

<script>
  export default {
    
  }
</script>

<style lang="scss" scoped>
  .loader {
    width: 3rem;
    height: 3rem;
    >img {
      width: 100%;
    }
  }
</style>
